/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Main } from './AdminUser.styles';
import { Container } from '../AdminStyles';
import { authenticateAdmin } from '../utils';
import { Web3 } from 'web3';
import config from '../../../config';
import useForm from '../../../utils/useForm';
import validateUser from './validateUser';
import { UserContext } from '../../../context/UserContext';
import SelectionModal from '../../SelectionModal/SelectionModal.component';
import Spinner from '../../Spinner/Spinner';
import RewardForm from '../RewardForm/RewardForm.component';
import RewardList from '../RewardList/RewardList.component';
import { RouterPrompt } from '../../RouterPrompt/RouterPrompt.component';
import { Icon } from '../../Icons/BaseIcon';
import { NavLink } from 'react-router-dom';
import { Auth, API } from 'aws-amplify';
import nftABI from '../../../utils/LOCGamePlayNFT.abi.json';
import { parseCardId } from '../../../utils/utils';
import { getCards } from '../../../utils/api';

const AdminUser = (props) => {
  const web3 = new Web3(config.maticRpc[0]);
  const nftAddress = config.nftAddress;
  const contract = new web3.eth.Contract(nftABI, nftAddress);
  const { user } = useContext(UserContext);
  const [searchEmail, setSearchEmail] = useState('');
  const [searchWallet, setSearchWallet] = useState('');
  const [userList, setUserList] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [issuedCards, setIssuedCards] = useState([]);
  const [cardData, setCardData] = useState(null);
  const [userCards, setUserCards] = useState(null);
  const [userCardTotal, setUserCardTotal] = useState(null);
  const [originalGroups, setOriginalGroups] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  let pollTxProc;

  const { values, errors, handleSubmit, handleChange, setValues, isDirty } =
    useForm(updateUser, validateUser);

  useEffect(() => {
    getCards().then((data) => setCardData(data));
  }, []);

  useEffect(() => {
    if (values.username) {
      fetchTransactions();
      fetchUsersGroups();
    }
  }, [values.username]);

  useEffect(() => {
    if (values['custom:wallet']) {
      fetchUserCards();
    }
  }, [values['custom:wallet']]);

  async function getCards() {
    const apiName = 'marketplace';
    const path = '/card';
    const options = {
      response: true,
      queryStringParameters: { limit: 200 },
    };
    let res = await API.get(apiName, path, options);
    setCardData(res.data.data);
  }

  async function fetchUserCards() {
    try {
      let cardsArr = await contract.methods
        .tokensOfOwner(values['custom:wallet'])
        .call();
      cardsArr = cardsArr.map((tokenId) => {
        const cardId = parseCardId(tokenId);
        const metadata = cardData.filter((data) => data.id === cardId);
        return metadata[0] ? { tokenId, ...metadata[0] } : null;
      });
      cardsArr = cardsArr.filter((card) => card !== null);
      cardsArr = cardsArr.map((card) => card.name);
      setUserCardTotal(cardsArr.length);
      cardsArr = Array.from(new Set(cardsArr));
      cardsArr = cardsArr.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 1;
      });
      setUserCards(cardsArr);
    } catch (err) {
      web3.setProvider(config.maticRpc[1]);
      let cardsArr = await contract.methods
        .tokensOfOwner(values['custom:wallet'])
        .call();
      cardsArr = cardsArr.map((tokenId) => {
        const cardId = parseCardId(tokenId);
        const metadata = cardData.filter((data) => data.id === cardId);
        return metadata[0] ? { tokenId, ...metadata[0] } : null;
      });
      cardsArr = cardsArr.filter((card) => card !== null);
      cardsArr = cardsArr.map((card) => card.name);
      setUserCardTotal(cardsArr.length);
      cardsArr = Array.from(new Set(cardsArr));
      cardsArr = cardsArr.sort((a, b) => {
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 1;
      });
      setUserCards(cardsArr);

      console.log({
        level: 'Error',
        message: "Unable to fetch User's Tokens",
        error: err,
      });
    }
  }

  async function fetchTransactions() {
    const apiName = 'marketplace';
    let path = `/order/user/${values.username}`;
    const myInit = {
      response: true,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      queryStringParameters: {
        sort: 'created=-1',
      },
    };
    let pendingTransactions, pendingAdminOrders, pendingIssuedCards;
    try {
      const res = await API.get(apiName, path, myInit);
      let rewardTransactions = res.data.data.filter(
        (item) => item?.staking?.name
      );
      pendingAdminOrders = rewardTransactions.filter(
        (tx) =>
          tx.status !== 'In Progress' &&
          tx.status !== 'Complete' &&
          tx.status !== 'Failed'
      );
      setTransactions(rewardTransactions);
      // Fetch Issued Cards
      path = `/reward/issue-card/${values.username}`;
      try {
        const res = await API.get(apiName, path, myInit);
        setIssuedCards(res.data.data);
        pendingIssuedCards = res.data.data.filter(
          (order) => order.status === 'Mint in Progress'
        );
      } catch (err) {
        console.log(`Error fetching issued cards - ${err}`);
      }
      pendingTransactions = [...pendingAdminOrders, ...pendingIssuedCards];
      if (pendingTransactions.length > 0) {
        setTimeout(fetchTransactions, 60000);
      }
    } catch (err) {
      console.log({
        level: 'Warning',
        message: 'Unable to fetch user transactions',
        error: err,
      });
    }
  }

  async function handleStatusChange(e) {
    e.preventDefault();
    if (values.status === undefined) return;
    const cognitoIdentitySP = await authenticateAdmin();
    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: values.username,
    };
    let confirm = window.confirm(
      `Change Users Account to ${values.status ? 'Disabled' : 'Enabled'}`
    );
    if (confirm) {
      try {
        if (values.status === true) {
          await cognitoIdentitySP.adminDisableUser(params).promise();
          setValues((prev) => ({ ...prev, status: !prev.status }));
        } else {
          await cognitoIdentitySP.adminEnableUser(params).promise();
          setValues((prev) => ({ ...prev, status: !prev.status }));
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function updateUser() {
    const cognitoIdentitySP = await authenticateAdmin();
    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: values.username,
      UserAttributes: [
        { Name: 'custom:firstName', Value: values.given_name },
        { Name: 'custom:lastName', Value: values.family_name },
        { Name: 'email', Value: values.email },
        { Name: 'custom:wallet', Value: values['custom:wallet'] || '' },
      ],
    };
    try {
      setShowSpinner(true);
      cognitoIdentitySP.adminUpdateUserAttributes(params).promise();
      if (
        values.groups.includes('Admin') &&
        !originalGroups.includes('Admin')
      ) {
        // add to admin
        params = {
          UserPoolId: config.cognito.aws_user_pools_id,
          Username: values.username,
          GroupName: 'Admin',
        };
        cognitoIdentitySP.adminAddUserToGroup(params).promise();
      }
      if (
        !values.groups.includes('Admin') &&
        originalGroups.includes('Admin')
      ) {
        params = {
          UserPoolId: config.cognito.aws_user_pools_id,
          Username: values.username,
          GroupName: 'Admin',
        };
        cognitoIdentitySP.adminRemoveUserFromGroup(params).promise();
      }
      if (
        values.groups.includes('investor') &&
        !originalGroups.includes('investor')
      ) {
        // add to investor
        params = {
          UserPoolId: config.cognito.aws_user_pools_id,
          Username: values.username,
          GroupName: 'investor',
        };
        cognitoIdentitySP.adminAddUserToGroup(params).promise();
      }
      if (
        !values.groups.includes('investor') &&
        originalGroups.includes('investor')
      ) {
        // remove from investor
        params = {
          UserPoolId: config.cognito.aws_user_pools_id,
          Username: values.username,
          GroupName: 'investor',
        };
        cognitoIdentitySP.adminRemoveUserFromGroup(params).promise();
      }
      setOriginalGroups(values.groups);
      setShowSpinner(false);
    } catch (err) {
      console.log(err);
      setShowSpinner(false);
    }
  }
  async function fetchUsersGroups() {
    const cognitoIdentitySP = await authenticateAdmin();
    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: values.username,
    };
    let { Groups: groups } = await cognitoIdentitySP
      .adminListGroupsForUser(params)
      .promise();
    groups = groups.map((item) => item.GroupName);
    setValues((prev) => ({ ...prev, groups }));
    setOriginalGroups(groups);
  }

  async function handleDeleteUser(e) {
    e.preventDefault();
    if (!values.username) return;
    const cognitoIdentitySP = await authenticateAdmin();
    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: values.username,
    };
    let confirm = window.confirm(`Delete user ${values.email}?`);
    if (confirm) {
      try {
        await cognitoIdentitySP.adminDeleteUser(params).promise();
        setValues({});
        console.log('User Deleted');
      } catch (err) {
        console.log(err);
      }
    }
  }

  async function searchUserByEmail(e) {
    e.preventDefault();
    if (!searchEmail) return;
    setValues({});
    const cognitoIdentitySP = await authenticateAdmin();

    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: user._id,
    };

    let usersGroups = await cognitoIdentitySP
      .adminListGroupsForUser(params)
      .promise();
    usersGroups = usersGroups.Groups.map((group) => group.GroupName);
    if (!usersGroups.includes('Admin')) return;

    params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      AttributesToGet: null,
      Filter: `email = "${searchEmail}"`,
    };

    cognitoIdentitySP.listUsers(params, (err, results) => {
      if (err) {
        console.log(err);
        return;
      }
      if (results.Users.length > 0) {
        let users = results.Users.map((user) => createUserObject(user));
        setUserList(users);
      } else {
        setUserList([{ email: 'User Not Found' }]);
      }
      setSearchEmail('');
    });
  }

  async function searchUserByWallet(e) {
    e.preventDefault();
    if (!searchWallet) return;
    setValues({});
    const cognitoIdentitySP = await authenticateAdmin();

    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: user._id,
    };

    let usersGroups = await cognitoIdentitySP
      .adminListGroupsForUser(params)
      .promise();
    usersGroups = usersGroups.Groups.map((group) => group.GroupName);
    if (!usersGroups.includes('Admin')) return;

    params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      AttributesToGet: null,
    };

    cognitoIdentitySP.listUsers(params, (err, results) => {
      if (err) {
        console.log(err);
        return;
      }
      let users = results.Users.filter((current) => {
        let user = createUserObject(current);
        return (
          String(user['custom:wallet']).toLowerCase() ===
          searchWallet.trim().toLowerCase()
        );
      });
      users = users.map((user) => createUserObject(user));
      setUserList(users);
      setSearchWallet('');
    });
  }

  const createUserObject = (user) => {
    const newUser = {
      username: user.Username,
      status: user.Enabled,
      signupDate: user.UserCreateDate,
    };
    user.Attributes.forEach((attr) => {
      newUser[attr.Name] = attr.Value;
    });
    return newUser;
  };

  const handleCheckbox = (e) => {
    let { name, checked } = e.target;
    if (!checked) {
      const groups = values.groups.filter((group) => group !== name);
      setValues((prev) => ({ ...prev, groups }));
    } else {
      setValues((prev) => ({ ...prev, groups: [...prev.groups, name] }));
    }
  };

  return (
    <Container>
      {showSpinner && <Spinner />}
      <RouterPrompt when={isDirty} onOK={() => true} onCancel={() => false} />
      {userList && (
        <SelectionModal
          sendClose={setUserList}
          setUser={setValues}
          userList={userList}
        />
      )}
      <header className='header'>
        <p className='heading'>
          Users
          <NavLink to='/admin/users/add'>
            <Icon
              src='/images/add.svg'
              width={20}
              height={20}
              margin='0 .5rem 0 1rem'
            />
            <span>Add New</span>
          </NavLink>
        </p>
      </header>
      <form>
        <input
          type='text'
          name='userEmail'
          id='userEmail'
          placeholder='Search User By Email'
          onChange={(e) => setSearchEmail(e.target.value)}
          value={searchEmail}
        />
        <button onClick={searchUserByEmail}>Search</button>
        <input
          type='text'
          name='userWallet'
          id='userWallet'
          size='35'
          placeholder='Search User By Wallet'
          onChange={(e) => setSearchWallet(e.target.value)}
          value={searchWallet}
        />
        <button onClick={searchUserByWallet}>Search</button>
      </form>
      <Main>
        {values.username && (
          <>
            <div className='column'>
              <h4>User Details</h4>
              <form className='details'>
                <p>
                  <span className='formHeader'> Username: </span>
                  {values.username}
                </p>
                <p>
                  <span className='formHeader'> Signup Date: </span>
                  {values.signupDate.toGMTString()}
                </p>

                <p>
                  <label> First Name: </label>{' '}
                  <input
                    type='text'
                    value={values['custom:firstName']}
                    name='custom:firstName'
                    onChange={handleChange}
                  />
                </p>
                <p>
                  <label> Last Name: </label>
                  <input
                    type='text'
                    name='custom:lastName'
                    value={values['custom:lastName']}
                    onChange={handleChange}
                  />
                </p>
                <p>
                  <label> Email: </label>
                  <input
                    type='text'
                    name='email'
                    size='35'
                    value={values.email}
                    onChange={handleChange}
                  />
                </p>
                <p>
                  <label> Wallet: </label>{' '}
                  <input
                    type='text'
                    name='custom:wallet'
                    value={values['custom:wallet']}
                    onChange={handleChange}
                    size='35'
                  />
                </p>
                <div className='formRow'>
                  <div className='inlineGroup'>
                    <label> Admin: </label>
                    <input
                      type='checkbox'
                      name='Admin'
                      checked={values.groups && values.groups.includes('Admin')}
                      onChange={handleCheckbox}
                    />
                  </div>
                  <div className='inlineGroup'>
                    <label> Investor / VIP: </label>
                    <input
                      type='checkbox'
                      name='investor'
                      checked={
                        values.groups && values.groups.includes('investor')
                      }
                      onChange={handleCheckbox}
                    />
                  </div>
                </div>
                <button onClick={handleSubmit}>Update User</button>
                <button className='delUser' onClick={handleDeleteUser}>
                  Delete User
                </button>
                <button onClick={handleStatusChange}>
                  {values.status ? 'Disable User' : 'Enable User'}
                </button>
              </form>
              {userCards && (
                <div className='token-list'>
                  <h5>
                    {userCards.length} Unique Cards / {userCardTotal} Total
                    Cards
                  </h5>
                  <button onClick={fetchUserCards}>Refresh List</button>
                  <ul>
                    {userCards.map((card, i) => (
                      <li className='user-card-item' key={i}>{`${
                        i + 1
                      }: ${card}`}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
            <div className='column'>
              <RewardForm
                user={values}
                fetchTransactions={fetchTransactions}
                cardData={cardData}
              />
              <RewardList
                user={values}
                fetchTransactions={fetchTransactions}
                transactions={transactions}
                issuedCards={issuedCards}
                setTransactions={setTransactions}
                cardData={cardData}
              />
            </div>
          </>
        )}
      </Main>
    </Container>
  );
};

export default AdminUser;
