import React from 'react';
import AdminMenu from '../../components/admin/AdminMenu/AdminMenu.component';
import { Container, Main, Section } from './AdminPanel.styles';
import AdminProduct from '../../components/admin/AdminProduct/AdminProduct.component';
import AdminUser from '../../components/admin/AdminUser/AdminUser.component';
import UserAdd from '../../components/admin/AdminUser/UserAdd';
import AdminCard from '../../components/admin/AdminCard/AdminCard.component';
import { Route, useRouteMatch } from 'react-router';
import ProductAdd from '../../components/admin/AdminProduct/ProductAdd';
import ProductEdit from '../../components/admin/AdminProduct/ProductEdit';
import NFTOrders from '../../components/admin/NFTOrders/NFTOrders.component';
import Discount from '../../components/admin/AdminDiscount/Discount.component';
import QuestSearch from '../Quests/QuestSearch.component';

const AdminPanel = (props) => {
  const menuItems = [
    'Products',
    'Cards',
    'Users',
    'NFT Orders',
    'Discounts',
    'Quests',
  ];
  const { url } = useRouteMatch();

  return (
    <Container>
      <AdminMenu menuItems={menuItems} />
      <Main>
        <Route
          path={`${url}nft-orders`}
          exact
          render={(props) => <NFTOrders {...props} />}
        />
        <Route
          path={`${url}discounts`}
          exact
          render={(props) => <Discount {...props} />}
        />
        <Route
          path={`${url}cards`}
          render={(props) => <AdminCard {...props} />}
        />
        <Section>
          <Route
            path={`${url}users`}
            exact
            render={(props) => <AdminUser {...props} />}
          />
          <Route
            path={`${url}users/add`}
            render={(props) => <UserAdd {...props} />}
          />
          <Route
            path={`${url}products`}
            exact
            render={(props) => <AdminProduct {...props} />}
          />
          <Route path={`${url}products/add`}>
            <ProductAdd />
          </Route>
          <Route
            path={`${url}products/edit/:id`}
            render={(props) => <ProductEdit {...props} />}
          />
          <Route
            path={`${url}quests`}
            exact
            render={(props) => <QuestSearch {...props} />}
          />
        </Section>
      </Main>
    </Container>
  );
};

export default AdminPanel;
