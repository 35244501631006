import React from 'react';
import { Container } from './ProductListItem.styles';
import { Icon } from '../../../Icons/BaseIcon';
import { useRouteMatch, NavLink } from 'react-router-dom';

const ProductListItem = ({ item }) => {
  const { url } = useRouteMatch();

  return (
    <Container>
      <div className='title'>{item.title}</div>
      <div className='type'>{item.productType === '001' ? 'Pack' : ''}</div>
      <div className='qty'>{`Sold ${item.soldQty}/${item.initialQty}`}</div>
      <div className='description'>{item.description}</div>
      <NavLink className='btn' to={`${url}/delete/${item._id}`}>
        <Icon
          src='/images/trashcan.svg'
          width={15}
          height={15}
          margin='0 10px'
        />
        Delete
      </NavLink>

      <NavLink className='btn' to={`${url}/edit/${item._id}`}>
        <Icon src='/images/edit.svg' width={15} height={15} margin='0 10px' />
        Edit
      </NavLink>
    </Container>
  );
};

export default ProductListItem;
