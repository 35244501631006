export default function validateCard(values) {
  let errors = [];
  const rarity = values.rarity;
  const vRarity = values.vRarity;
  const rarityTotal = Math.round(
    (rarity['001'] ?? 0) +
      (rarity['002'] ?? 0) +
      (rarity['003'] ?? 0) +
      (rarity['004'] ?? 0) +
      (rarity['005'] ?? 0)
  );
  const vRarityTotal = Math.round(
    (vRarity['001'] ?? 0) +
      (vRarity['002'] ?? 0) +
      (vRarity['003'] ?? 0) +
      (vRarity['004'] ?? 0) +
      (vRarity['005'] ?? 0)
  );

  if (!values.set) errors.push('An Edition Set must be selected.');

  if (!values.pack) errors.push('A Pack must be selected');

  if (rarityTotal !== 1) errors.push('Rarity must total 100%');

  if (vRarityTotal !== 1) errors.push('Visual Rarity must total 100%');

  if (errors.length > 0) return { errors };
  else return {};
}
