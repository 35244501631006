import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
`;

export const Menu = styled.ul`
  list-style: none;
  display: flex;
  align-items: center;
  margin: 0 0 1rem 2rem;

  li {
    padding: 0 1.25rem;
    text-align: center;
    font: ${({ theme }) => theme.font.big};
    font-size: ${({ menuType }) =>
      menuType === 'main' ? '1rem' : menuType === 'sub' ? '.7rem' : '1rem'};
    letter-spacing: 1.5px;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .active {
    border-bottom: 2px solid ${({ theme }) => theme.purple};
  }
`;
