import React, { useState, useEffect } from 'react';
import { Container } from '../AdminStyles';
import { NavLink } from 'react-router-dom';
import AdminMenu from '../AdminMenu/AdminMenu.component';
import { API } from 'aws-amplify';
import ProductListItem from './ProductListItem/ProductListItem.component';
import { Icon } from '../../Icons/BaseIcon';
import Search from '../../Search/Search.component';
import { getProducts } from '../../../utils/api';
const AdminProduct = (props) => {
  const menuItems = ['Add'];
  const [products, setProducts] = useState(null);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [filters, setFilters] = useState([]);
  const [onlyVisible, setOnlyVisible] = useState(false);

  useEffect(() => {
    getProducts().then((data) => {
      data = data.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();

        if (titleA < titleB) return -1;
        return 1;
      });
      setProducts(data);
      setFilteredProducts(data);
    });
  }, []);

  useEffect(() => {
    if (filters.search) {
      let re = new RegExp(filters.search, 'i');
      const filteredSet = products.filter((item) => re.test(item.title));
      setFilteredProducts(filteredSet);
    } else {
      setFilteredProducts(products);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    if (onlyVisible && filteredProducts) {
      const filteredSet = filteredProducts?.filter((item) => item.visible);
      setFilteredProducts(filteredSet);
    } else {
      setFilteredProducts(products);
    }
  }, [onlyVisible]);

  return (
    <Container>
      {filteredProducts && (
        <>
          <header className='header'>
            <p className='heading'>
              Products
              <NavLink to='/admin/products/add'>
                <Icon
                  src='/images/add.svg'
                  width={20}
                  height={20}
                  margin='0 .5rem 0 1rem'
                />
                <span>Add New</span>
              </NavLink>
            </p>
            <Search filter={filters} setFilters={setFilters} />
          </header>
          <div className='group'>
            <input
              type='checkbox'
              name='visible'
              id='visible'
              checked={onlyVisible}
              onChange={(e) => setOnlyVisible(e.target.checked)}
            />
            <label htmlFor='visible'>Only Visible Packs</label>
          </div>
          <ul>
            {filteredProducts.map((item) => (
              <ProductListItem key={item._id} item={item} />
            ))}
          </ul>
        </>
      )}
    </Container>
  );
};

export default AdminProduct;
