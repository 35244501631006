/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { RouterPrompt } from '../../RouterPrompt/RouterPrompt.component';
import useForm from '../../../utils/useForm';
import CardItem from '../CardItem/CardItem.component';
import validateProduct from './validateProduct';
import { Auth, API, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

Storage.configure({
  customPrefix: {
    public: '',
  },
});

const ProductAdd = () => {
  const {
    values,
    errors,
    handleSubmit,
    handleChange,
    setValues,
    isDirty,
    setIsDirty,
  } = useForm(addProduct, validateProduct);

  const [imageURLs, setImageURLs] = useState([]);
  const [productTypes, setProductTypes] = useState(null);

  useEffect(() => {
    getProductTypes();
    setValues({
      title: '',
      description: '',
      descriptionLong: '',
      price: 0.0,
      productType: '001',
      initialQty: 0,
      maxPurchase: 0,
      images: [undefined, undefined, undefined],
      items: [],
      tags: [],
      visible: false,
    });
    setIsDirty(false);
  }, []);

  async function addProduct() {
    const apiName = 'marketplace';
    const path = '/product';
    const myInit = {
      body: values,
      headers: {
        Authorization: `${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
      response: true,
    };
    try {
      await API.post(apiName, path, myInit);
      setValues({
        title: '',
        description: '',
        descriptionLong: '',
        price: 0.0,
        productType: '001',
        initialQty: 0,
        maxPurchase: 0,
        images: [undefined, undefined, undefined],
        items: [],
        tags: [],
        visible: false,
      });
    } catch (err) {
      console.log(err);
    }
  }

  const addImageURL = (e) => {
    e.preventDefault();
    if (!values.imageURL) return;
    if (!values.images) {
      setValues((prevValues) => {
        return {
          ...prevValues,
          images: [prevValues.imageURL.toLowerCase()],
          imageURL: '',
        };
      });
    } else {
      setValues((prevValues) => {
        return {
          ...prevValues,
          images: [...prevValues.images, values.imageURL.toLowerCase()],
          imageURL: '',
        };
      });
    }
  };
  const addTag = (e) => {
    e.preventDefault();
    if (!values.tag) return;
    if (!values.tags) {
      setValues((prevValues) => {
        return { ...prevValues, tags: [prevValues.tag.toLowerCase()], tag: '' };
      });
    } else {
      setValues((prevValues) => {
        return {
          ...prevValues,
          tags: [...prevValues.tags, values.tag.toLowerCase()],
          tag: '',
        };
      });
    }
  };

  const deleteImageURL = (e) => {
    e.preventDefault();
    let images = values.images.filter((image) => image !== e.target.id);
    setValues((prevValues) => {
      return { ...values, images };
    });
  };
  const deleteTag = (e) => {
    e.preventDefault();
    let tags = values.tags.filter((tag) => tag !== e.target.id);
    setValues((prevValues) => {
      return { ...values, tags };
    });
  };

  const getProductTypes = async () => {
    const apiName = 'marketplace';
    const path = '/enum/productType';
    const res = await API.get(apiName, path);
    setProductTypes(res.values);
  };
  const displayProductTypes = () => {
    if (!productTypes) return;
    let keys = Object.keys(productTypes);
    return keys.map((key) => (
      <option key={key} value={key}>
        {productTypes[key]}
      </option>
    ));
  };

  const handleImageUpload = async (e) => {
    const image = e.target.files[0];
    const name = e.target.name;
    const images = values.images;
    let currentUploadedImage;
    if (!image) return;
    if (name === 'cardImage') currentUploadedImage = images[0];
    if (name === 'bgImage') currentUploadedImage = images[1];
    if (name === 'packImage') currentUploadedImage = images[2];
    if (currentUploadedImage) {
      currentUploadedImage = currentUploadedImage.slice(26);
    }
    let filename = uuidv4();
    try {
      const res = await Storage.put(`products/${filename}`, image, {
        contentType: image.type,
      });
      if (currentUploadedImage) {
        Storage.remove(currentUploadedImage);
      }
      if (name === 'cardImage')
        images[0] = `https://static.locgame.io/${res.key}`;
      if (name === 'bgImage')
        images[1] = `https://static.locgame.io/${res.key}`;
      if (name === 'packImage')
        images[2] = `https://static.locgame.io/${res.key}`;
      setValues((prev) => {
        return { ...prev, images };
      });
      setImageURLs(images);
      setIsDirty(true);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOK = () => {
    if (imageURLs.length > 0) {
      imageURLs.forEach((image) => {
        if (image) {
          Storage.list(image.slice(26)).then((result) => {
            if (result.length > 0) Storage.remove(result[0].key);
          });
        }
      });
    }
    return true;
  };

  return (
    <section>
      <RouterPrompt when={isDirty} onOK={handleOK} onCancel={() => false} />
      <h4>Add Product</h4>
      <form onSubmit={handleSubmit}>
        <div className='formRow'>
          <div className='inputGroup'>
            <label htmlFor='title'>Product Title</label>
            <input
              type='text'
              name='title'
              id='title'
              onChange={handleChange}
              value={values.title}
              size='27'
            />
          </div>
          <div className='inputGroup'>
            <label htmlFor='productType'>Type</label>
            <select
              name='productType'
              id='productType'
              value={values.productType}
              onChange={handleChange}
            >
              {displayProductTypes()}
            </select>
          </div>

          <div className='inputGroup'>
            <label htmlFor='initialQty'>Price (USDT)</label>
            <input
              type='text'
              name='price'
              id='price'
              onChange={handleChange}
              value={values.price}
              size='6'
            />
          </div>
        </div>

        <div className='inputGroup'>
          <label htmlFor='description'>Short Description</label>
          <textarea
            type='text'
            name='description'
            id='description'
            rows='2'
            maxLength='125'
            onChange={handleChange}
            value={values.description}
          />
        </div>
        <div className='inputGroup'>
          <label htmlFor='descriptionLong'>Long Description</label>
          <textarea
            type='text'
            name='descriptionLong'
            id='descriptionLong'
            rows='4'
            onChange={handleChange}
            value={values.descriptionLong}
          />
        </div>

        <div className='formRow'>
          <div className='inputGroup'>
            <label htmlFor='initialQty'>Initial Qty</label>
            <input
              type='text'
              name='initialQty'
              id='initialQty'
              onChange={handleChange}
              value={values.initialQty}
              size='6'
            />
          </div>
          <div className='inputGroup'>
            <label htmlFor='maxPurchase'>Max Bulk Purchase</label>

            <input
              type='text'
              name='maxPurchase'
              id='maxPurchase'
              size='8'
              value={values.maxPurchase}
              onChange={handleChange}
            />
          </div>
          <div className='inputGroup'>
            <label htmlFor='tag'>Tag</label>
            <div>
              <input
                type='text'
                name='tag'
                id='tag'
                size='8'
                value={values.tag}
                onChange={handleChange}
              />
              <button onClick={addTag}>Add Tag</button>
            </div>
            <ul className='formList'>
              {values.tags &&
                values.tags.map((tag, i) => (
                  <li key={i}>
                    {tag}
                    <div className='delBtn' onClick={deleteTag} id={tag}>
                      X
                    </div>
                  </li>
                ))}
            </ul>
          </div>
          <div className='inputGroup'>
            <label htmlFor='visible'>Visible</label>
            <input
              type='checkbox'
              name='visible'
              id='visible'
              onChange={(e) =>
                setValues({ ...values, visible: e.target.checked })
              }
              checked={values.visible}
            />
          </div>
        </div>
        <h5 className='labelHeading'>Images</h5>
        <div className='formRow'>
          <div className='inputGroup'>
            <label htmlFor='cardImage'>Product Card Image</label>
            <input
              type='file'
              name='cardImage'
              id='cardImage'
              onChange={handleImageUpload}
            />
          </div>
          <div className='inputGroup'>
            <label htmlFor='bgImage'>Background Image</label>
            <input
              type='file'
              name='bgImage'
              id='bgImage'
              onChange={handleImageUpload}
            />
          </div>
        </div>
        <div className='inputGroup'>
          <label htmlFor='packImage'>Single Product Image</label>
          <input
            type='file'
            name='packImage'
            id='packImage'
            onChange={handleImageUpload}
          />
        </div>
        {values.productType === '001' && (
          <>
            <h5 className='labelHeading'>Items</h5>
            <div className='formRow'>
              <CardItem setProductValues={setValues} productValues={values} />
            </div>
          </>
        )}
        <input type='submit' value='Add Product' onClick={handleSubmit} />
      </form>
    </section>
  );
};

export default ProductAdd;

{
  /* <div className='inputGroup'>
            <label htmlFor='images'>Image Url</label>
            <div>
              <input
                type='text'
                name='imageURL'
                id='imageURL'
                size='30'
                value={values.imageURL}
                onChange={handleChange}
              />
              <button onClick={addImageURL}>Add URL</button>
            </div>
            <ul className='formList'>
              {values.images &&
                values.images.map((image, i) => (
                  <li key={i}>
                    {image}
                    <div className='delBtn' onClick={deleteImageURL} id={image}>
                      X
                    </div>
                  </li>
                ))}
            </ul>
          </div> */
}
