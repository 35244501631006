import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  p,
  input,
  select {
    min-width: 150px;
    height: unset;
    margin: 0;
    margin-right: 1rem;
    padding: 0.25rem 0.5rem;
  }

  p {
    width: 100px;
  }
  input {
    width: 100px;
  }

  .btn {
    cursor: pointer;
  }
`;
