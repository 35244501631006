import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 200;
  // min-width: 100vw;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3rem;
  backdrop-filter: blur(4px);
  overflow: auto;

  @media screen and (max-height: 885px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 485px) {
    padding: 0;
  }
`;

export const Modal = styled.div`
  display: flex;
  position: relative;
  padding: 1rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #362e55;
  width: 485px;
  color: ${({ theme }) => theme.fontColor};

  h4 {
    margin-bottom: 2rem;
  }

  .controls {
    margin-top: 1rem;
  }
`;
