/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Icon } from '../Icons/BaseIcon';
import { Container, Username, Menu, MenuItems, Link } from './UserMenu.styles';
import config from '../../config';
import { Auth } from 'aws-amplify';
import { HandleOutsideDropdown } from '../../utils/utils';

const UserMenu = (props) => {
  const { marketplaceURL } = config;
  const [showMenu, setShowMenu] = useState(false);
  const { ref, isComponentVisible } = HandleOutsideDropdown();

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  useEffect(() => {
    if (!isComponentVisible) {
      toggleMenu();
    }
  }, [isComponentVisible, toggleMenu]);

  return (
    <Container onClick={toggleMenu} className='dropdown'>
      <Icon
        className='dropdown'
        src={props.picture ? props.picture : '/images/user.svg'}
        height={45}
        width={45}
        borderRadius='50%'
      />
      <Username className='dropdown'>{props.username} </Username>{' '}
      &nbsp;&#x025BE;
      <Menu className={showMenu ? 'visible' : null} ref={ref}>
        <MenuItems>
          <Link className='dropdown'>
            <a href={marketplaceURL}>
              {/* <Icon src='/images/log-out.svg' height={18} width={18} /> */}
              Marketplace
            </a>
          </Link>
          <Link
            className='dropdown'
            onClick={() => {
              Auth.signOut();
            }}
          >
            <Icon src='/images/log-out.svg' height={18} width={18} /> Log out
          </Link>
        </MenuItems>
      </Menu>
    </Container>
  );
};

export default UserMenu;
