import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Icon } from '../../Icons/BaseIcon';
import { Container } from './Discount.styles';
import { getAllDiscounts } from '../../../utils/api';
import DiscountItem from './DiscountItem.component';

const Discount = () => {
  const [discounts, setDiscounts] = useState(null);

  useEffect(() => {
    updateDiscounts();
  }, []);

  const updateDiscounts = () => {
    getAllDiscounts().then((data) => {
      data.sort((a, b) => {
        if (a.code > b.code) return 1;
        if (a.code < b.code) return -1;
        return 0;
      });
      setDiscounts(data);
    });
  };

  const handleAdd = () => {
    setDiscounts((prevDiscounts) => [
      {
        _id: 'NEW_CODE',
        code: '',
        type: 'percent',
        value: '',
        active: false,
        isSingleUse: true,
        isNew: true,
      },
      ...prevDiscounts,
    ]);
  };

  return (
    <Container>
      <p>
        Discounts
        <span onClick={handleAdd}>
          <Icon
            src='/images/add.svg'
            width={20}
            height={20}
            margin='0 .5rem 0 1rem'
          />
          <span>Add New</span>
        </span>
      </p>
      <ul>
        {discounts &&
          discounts.map((discount) => (
            <li key={discount._id}>
              <DiscountItem
                key={discount._id}
                discount={discount}
                updateDiscounts={updateDiscounts}
              />
            </li>
          ))}
      </ul>
    </Container>
  );
};

export default Discount;
