import styled from 'styled-components';

export const Container = styled.div`
  width: 97%;
  max-width: 1500px;
  padding: 1rem 2rem;

  .heading {
    margin-bottom: 2rem;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
  }
`;
export const Main = styled.main`
  width: 100%;
`;

export const Section = styled.section`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  &.column {
    flex-direction: column;
    align-items: center;
  }

  .data {
    width: 100%;
    max-width: 1000px;
    margin: 1rem;
  }

  .player-name {
    margin-bottom: 1.5rem;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 600;
  }

  li {
    margin: 0.25rem 0;
    width: 100%;
    max-width: 950px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .data-item {
    padding: 0.25rem 0.75rem;
    margin-right: 0.5rem;
    min-width: 95px;

    &.win {
      background-color: rgba(98, 182, 98, 0.8);
    }
    &.lose {
      background-color: rgba(204, 97, 97, 0.8);
    }
    &.result {
      margin: none;
      width: 132px;
    }
  }

  .pagination {
    display: flex;
    align-items: center;
    margin: 2rem 0;
    width: max-content;
    max-width: 700px;

    .back,
    .next {
      width: 50px;
      height: 50px;
      border: 1px solid black;
      border-radius: 50%;
      /* background-color: ${({ theme }) => theme.inputDefaultColor}; */
      margin: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;

      img {
        width: 30px;
        height: 30px;
        z-index: 1;
        pointer-events: none;
      }
    }

    .page-list {
      display: flex;
      flex-basis: 1;
      padding: 0 0.5rem;
      max-width: 550px;
      overflow: hidden;

      p {
        font-size: 20px;
        font-weight: 500;
        opacity: 0.8;
        margin-right: 0.5rem;
        cursor: pointer;

        &.active {
          opacity: 1;
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }
  .input-group {
    height: 100px;
    min-width: 90px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    button {
      margin: 0;
    }
  }
`;
