import React from 'react';
import { Container, Column, Wrapper } from './Footer.styles';
import { Icon } from '../Icons/BaseIcon';
import { NavLink } from 'react-router-dom';

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <div className='footer-logos'>
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618f18a9f6c6e029c6735390_1adc57c94ede17b949985e31cc246e1c.png'
            alt='iOS'
          />
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618f18a88a00de2339025742_1280px-MacOS_wordmark_(2017).svg.png'
            alt='MacOS'
          />
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618f18a81f12a09fb70d88c0_Android-Logo.png'
            alt='Android'
          />
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618f18a9dc1f694ccaa67181_windows-logo-mygalaxy-view-singularlogic-6.png'
            alt='Windows'
          />
          <img
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618f18a844ab470c6b8feac5_Font-of-the-Linux-Logo.png'
            alt='Linux'
          />
        </div>
        <div className='footer-content'>
          <Column>
            <img
              src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618d462ee88384dcbe751fd9_NAv%20Logo.svg'
              alt='Logo'
              className='footer-logo'
            />
            <ul className='footer-list'>
              <li className='footer-list-item'>info@locgame.io</li>
              <li className='footer-list-item'>
                1072BT Amsterdam, Netherlands
              </li>
              <li className='footer-list-item'>© 2021 Rbl Labs</li>
            </ul>
          </Column>
          <div className='footer-group'>
            <Column>
              <div className='item-wrapper'>
                <a
                  href='https://discord.com/invite/aPf6MTqTAj'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb114d26593c8c0720068_discord.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Discord</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://www.linkedin.com/company/legends-of-crypto-loc/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb115e44290539c811c76_linkedin.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Linkedin</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://www.instagram.com/loc.game/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb114f3d05507752aa521_instagram.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Instagram</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://www.reddit.com/r/LOCGame/'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb1152973b129eff78460_Reddit.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Reddit</p>
              </div>
            </Column>
            <Column>
              <div className='item-wrapper'>
                <a
                  href='https://medium.com/locgame'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb1150a147c16fa8dadf7_Medium.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Medium</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://open.kakao.com/o/glQP3i5c'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb115d9d4ea8ba1c161c6_KakaoTalk.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>KaKaoTalk</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://t.me/loc_game'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb114323174e59ac29f5b_Telegram.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Telegram</p>
              </div>
              <div className='item-wrapper'>
                <a
                  href='https://twitter.com/LOCgameio?s=20'
                  target='_blank'
                  rel='noreferrer'
                >
                  <div className='icon-wrapper'>
                    <Icon
                      src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618eb115f9715f6750aab713_twitter.svg'
                      width='20'
                    />
                  </div>
                </a>
                <p>Twitter</p>
              </div>
            </Column>
          </div>
          <Column>
            <h4 className='heading'>About Us</h4>

            <ul className='footer-list'>
              <li className='footer-list-item'>
                <a href='https://rbllabs.com/'>Company Website</a>
              </li>
              <li className='footer-list-item'>
                <a href='https://cryptorebel.io/'>Crypto Rebel</a>
              </li>
              <li className='footer-list-item'>
                <a href='https://www.locgame.io/company'>Career at LOCGame</a>
              </li>
              <li className='footer-list-item'>
                <NavLink to='/support'>Support</NavLink>
              </li>
            </ul>
          </Column>
          <Column>
            <h4 className='heading'>Buy $LOCG</h4>

            <ul className='footer-list'>
              <li className='footer-list-item'>
                <a
                  href='https://trade.kucoin.com/LOCG-USDT'
                  alt='Kucoin Website'
                  target='_blank'
                  rel='noreferrer'
                >
                  Kucoin
                </a>
              </li>
              <li className='footer-list-item'>
                <a
                  href='https://www.gate.io/trade/LOCG_USDT'
                  alt='Gate.io Website'
                  target='_blank'
                  rel='noreferrer'
                >
                  Gate.io
                </a>
              </li>
              <li className='footer-list-item'>
                <a
                  href='https://app.uniswap.org/#/swap?outputCurrency=0x60Eb57d085C59932d5fAa6c6026268A4386927d0'
                  target='_blank'
                  rel='noreferrer'
                >
                  Uniswap
                </a>
              </li>
              <li className='footer-list-item'>
                <a
                  href='https://coinmarketcap.com/currencies/locgame'
                  target='_blank'
                  rel='noreferrer'
                >
                  Coinmarketcap
                </a>
              </li>
            </ul>
          </Column>
        </div>
        <div className='footer-row'>
          <a href='https://www.locgame.io/privacy-policy' alt='Privacy Policy'>
            Privacy Policy
          </a>
          <p>LOCGame is a trademark of RBL Labs BV</p>
          <p>Licensing</p>
          <a href='https://locgame.io/company'>About Us</a>
        </div>
      </Container>
    </Wrapper>
  );
};

export default React.memo(Footer);
