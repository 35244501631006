import React from 'react';
import { Container, Main } from '../Page.styles';

const BlankPage = () => {
  return (
    <Container>
      <Main
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2 style={{ margin: '0 auto 2rem' }}>Unauthorized User</h2>
        <img
          src='/images/no-entry.webp'
          alt='No Entry'
          style={{ width: '50%' }}
        />
      </Main>
    </Container>
  );
};

export default BlankPage;
