/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Browser, Route, Switch } from 'react-router-dom';
import { lightTheme, darkTheme, GlobalStyles } from './themes';
import Navbar from './components/Navbar/Navbar.component';
import Footer from './components/Footer/Footer.component';
import { UserContext } from './context/UserContext';
import { Amplify, Auth, Hub } from 'aws-amplify';
import AdminPanel from './pages/AdminPanel/AdminPanel';
import config from './config';
import LoginPage from './pages/Login/Login.component';
import BlankPage from './pages/Blank/Blank.component';


Amplify.configure(config.cognito);


function App() {
  const [theme] = useState('dark');
  const { user, setUser } = useContext(UserContext);
  const [loginModal, loginModalSet] = useState(false);


  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      console.log(event);
      switch (event) {
        case 'signIn':
        case 'federatedSignIn':
          getUser();
          loginModalSet(false);
          break;
        case 'oAuthSignOut':
        case 'signOut':
          signOut();
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log({ level: 'Warning', message: 'Sign in failure', data });
          break;
      }
    });

    getUser();
  }, []);


  function signOut() {
    window.location.assign(config.cognito.oauth.redirectSignOut);
    setUser(null);
    localStorage.removeItem('token');
  }

  function toggleLoginModal() {
    loginModalSet(!loginModal);
  }



  async function getUser() {
    const getAvatar = (user) => {
      if (user.attributes['custom:avatar'])
        return `http://static.locgame.io/public/${user.attributes['custom:avatar']}`;
      else if (user.attributes.picture) return user.attributes.picture;
      else return '/images/user.svg';
    };
    try {
      const user = await Auth.currentAuthenticatedUser();
      const token = user.signInUserSession.idToken.jwtToken;
      const roles =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      localStorage.setItem('token', token);
      let attributes = {
        _id: user.username,
        given_name:
          user.attributes['custom:firstName'] || user.attributes.given_name,
        family_name:
          user.attributes['custom:lastName'] || user.attributes.family_name,
        picture: getAvatar(user),
        email: user.attributes.email,
        roles,
        wishlist: user.attributes['custom:wishlist'],
      };
      if (attributes.wishlist) {
        attributes.wishlist = JSON.parse(attributes.wishlist);
      }
      setUser(attributes);
    } catch (err) {
      setUser(null);
    }
  }


  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      <Browser>
        {loginModal && <LoginPage />}
        <Navbar
          toggleLoginModal={toggleLoginModal}
        />
        <Switch>
          <Route
            path='/'
            render={(props) => (
              (user && user.roles && user.roles.includes('Admin') && <AdminPanel />) || <BlankPage />)}
          />
        </Switch>
        <Footer />
      </Browser>
    </ThemeProvider>
  );
}

export default App;
