import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  input,
  select {
    margin: 0 2rem;
  }
  .details {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ul {
    margin: 2rem;
  }
  li {
    max-width: 950px;
    padding: 10px;
    p {
      min-width: 150px;
    }
  }
  li:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.2);
  }
  li:nth-child(odd) {
    background-color: rgba(255, 255, 255, 0.2);
  }
  span.num {
    width: 60px;
  }
`;
