import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;

  .column {
    width: 48%;
  }

  .user-card-item {
    padding: 4px 5px;
    width: 250px;
  }
`;
