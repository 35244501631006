export default function validateUser(values) {
  let errors = {};

  // Sanatize Input
  values['custom:firstName'] = values['custom:firstName'].trim();
  values['custom:lastName'] = values['custom:lastName'].trim();
  values.email = values.email.trim().toLowerCase();

  if (values.wallet) {
    values['custom:wallet'] = values['custom:wallet'].trim().toLowerCase();
  }

  if (!values['custom:firstName']) {
    errors['custom:firstName'] = 'First Name is required.';
  }
  if (!values['custom:lastName']) {
    errors['custom:lastName'] = 'Last Name is required.';
  }
  if (!values.email || !/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'A Valid email is required.';
  }

  return errors;
}
