import styled from 'styled-components';

export const Nav = styled.nav`
  background: ${({ theme }) => theme.navBgColor};
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 50px 0 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${({ theme }) => theme.navHeight};
  z-index: 30;

  .wrapper {
    display: flex;
    align-items: center;
  }

  .login-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .signup {
    height: 45px;
    border: 3px solid rgba(255, 255, 255, 0.15);
    border-radius: 0 0 0 6em;
    width: 150px;
    padding: 0 1rem 0;
    transform: translateX(2rem);
    font: ${({ theme }) => theme.font.button};
    color: ${({ theme }) => theme.fontMuted};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.15);
      color: ${({ theme }) => theme.fontColor};
    }
  }

  // nav custom settings
  .nav-link {
    font: 500 18px/24px TTHoves;
    color: ${({ theme }) => theme.fontColor};
    display: flex;
    position: relative;
    align-items: center;
    padding: 0 15px;
    height: 100%;
    cursor: pointer;

    &.game-dd:hover .nav-drop-down.game {
      display: flex;
    }
    &.more-dd:hover .nav-drop-down.more {
      display: flex;
    }
  }

  .nav-icon {
    width: 16px;
    margin-left: 15px;
    filter: invert(100%);
  }

  .nav-drop-down {
    width: 260px;
    background: ${({ theme }) => theme.navBgColor};
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    transform: translateY(${({ theme }) => theme.navHeight});

    a.nav-drop-down-link {
      width: 100%;
      padding: 15px;
      border-bottom: 1px solid ${({ theme }) => theme.navBorder};

      &:hover {
        padding-left: 20px;
        ${({ theme }) => theme.navHighlight};
        background-color: ${({ theme }) => theme.navHoverBg};
        transition: padding-left 0.3s, background-color 0.3s;
      }
    }

    &.game,
    &.more {
      display: none;
    }
  }

  .mobile-toggle {
    width: ${({ theme }) => theme.navHeight};
    height: ${({ theme }) => theme.navHeight};
    display: none;
    align-items: center;
    justify-content: center;

    .nav-icon {
      margin: 0;
      width: 70%;
    }

    @media screen and (max-width: 991px) {
      display: flex;
      padding: 0 1rem 0 1rem;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0 1rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      height: 35px;
      font-size: 14px;
      line-height: 16px;
    }
    .signup {
      padding-right: 1.75rem;
    }
  }
  @media screen and (max-width: 390px) {
    padding: 0 1rem 0 1rem;

    .loginBtn,
    .signup {
      width: 100px;
      height: 35px;
      font-size: 12px;
      line-height: 14px;
    }
    .signup {
      padding-right: 1.75rem;
    }
  }
`;

export const Image = styled.img`
  width: 80px;
`;

export const Icon = styled.img`
  display: inline-block;
  height: 25px;
  width: 25px;
`;

export const NavMenuDesktop = styled.ul`
  display: flex;
  height: ${({ theme }) => theme.navHeight};
  list-style: none;
  @media screen and (max-width: 991px) {
    display: none;
  }
`;
export const NavMenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1;
  background-color: ${({ theme }) => theme.navBgColor};

  .nav-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 45px;
    width: 45px;
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 30px 10px;
    border-radius: 50%;
  }

  .nav-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: space-between;
  }

  p {
    font: 400 12px/1em 'TTHoves';
    letter-spacing: 0.15em;
    margin-bottom: 20px;
  }
  .nav-column {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 30px;

    a {
      font: 800 28px/28px TTHoves;
      padding: 4px 0;
    }
  }

  @media screen and (max-width: 991px) {
    display: flex;
  }

  @media screen and (max-width: 767px) {
    .nav-content {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-top: 70px;
    }
  }

  @media screen and (max-width: 595px) {
    .nav-column {
      width: 100%;
    }
  }

  @media screen and (max-width: 475px) {
    .nav-column {
      a {
        font-size: 22px;
        line-height: 22px;
        font-weight: 600;
        padding: 2px;
      }
    }
  }
`;

// export const Link = styled.li`
//   display: inline-block;
//   font: ${({ theme }) => theme.font.button};
//   font-weight: 400;
//   margin: 0 1.5rem;
// `;
