import React from 'react';

const CardAdd = (props) => {
  return (
    <div>
      <h3>Add Card</h3>
    </div>
  );
};

export default CardAdd;
