import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import Spinner from '../../components/Spinner/Spinner';
import { sleep } from '../../utils/utils';
import { Container, Main } from './LoginPage.styles';
import reactDom from 'react-dom';

const LoginPage = () => {
  const [values, valuesSet] = useState({});
  const [showSpinner, showSpinnerSet] = useState(false);
  const [user, userSet] = useState(null);

  function handleChange(e) {
    const { name, value } = e.target;
    valuesSet((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  async function loginUser() {
    showSpinnerSet(true);
    try {
      const user = await Auth.signIn(values.email);
      userSet(user);
    } catch (error) {
      if (error?.message?.includes('Incorrect username or password')) {
        valuesSet((prev) => ({
          ...prev,
          errors: {
            email: 'Invalid email or password',
          },
        }));
      }
    } finally {
      showSpinnerSet(false);
    }
  }

  async function submitCode() {
    if (user.challengeName !== 'CUSTOM_CHALLENGE' || !values.code) {
      return;
    }
    try {
      showSpinnerSet(true);
      await Auth.sendCustomChallengeAnswer(user, values.code);
      await sleep(500);
    } catch (err) {
      console.log(err?.message || err);
    } finally {
      showSpinnerSet(false);
    }
  }
  return reactDom.createPortal(
    <Container>
      {showSpinner && <Spinner />}
      <Main>
        <h2>Login</h2>
        <div>
          <label htmlFor='email'>Email</label>
          <input type='email' id='email' name='email' onChange={handleChange} />
        </div>
        <button
          disabled={!/\S+@\S+\.\S+/.test(values.email)}
          onClick={loginUser}
        >
          Request Code
        </button>
        <div>
          <label htmlFor='code'>Code</label>
          <input
            type='text'
            id='code'
            name='code'
            disabled={!/\S+@\S+\.\S+/.test(values.email)}
            onChange={handleChange}
          />
        </div>
        <button disabled={values?.code?.length !== 6} onClick={submitCode}>
          Send Code
        </button>
      </Main>
    </Container>,
    document.getElementById('portal')
  );
};

export default LoginPage;
