import React, { useState, useEffect } from 'react';
import { Main } from './AdminUser.styles';
import Spinner from '../../Spinner/Spinner';
import useForm from '../../../utils/useForm';
import validateUser from './validateUser';
import { authenticateAdmin } from '../utils';
import config from '../../../config';

const UserAdd = (props) => {
  const initialValues = {
    'custom:firstName': '',
    'custom:lastName': '',
    email: '',
    wallet: '',
    admin: false,
    investor: false,
  };
  const [showSpinner, setShowSpinner] = useState(false);
  const { values, errors, handleSubmit, handleChange, setValues, isDirty } =
    useForm(createUser, validateUser, initialValues);

  const handleCheckbox = (e) => {
    let { name, checked } = e.target;
    setValues((prev) => {
      return { ...prev, [name]: checked };
    });
  };

  async function createUser() {
    setShowSpinner(true);
    const cognitoIdentitySP = await authenticateAdmin();
    let params = {
      UserPoolId: config.cognito.aws_user_pools_id,
      Username: values.email,
      DesiredDeliveryMediums: ['EMAIL'],
      TemporaryPassword: 'L0Cv!p22',
      UserAttributes: [
        { Name: 'custom:firstName', Value: values['custom:firstName'] },
        { Name: 'given_name', Value: values['custom:firstName'] },
        { Name: 'custom:lastName', Value: values['custom:lastName'] },
        { Name: 'family_name', Value: values['custom:lastName'] },
        { Name: 'email', Value: values.email },
        { Name: 'custom:wallet', Value: values['custom:wallet'] || '' },
        { Name: 'email_verified', Value: 'True' },
      ],
    };
    try {
      let user = await cognitoIdentitySP.adminCreateUser(params).promise();
      console.log(user);
      if (values.admin || values.investor) {
        const username = user.User.Username;

        let params = {
          UserPoolId: config.cognito.aws_user_pools_id,
          Username: username,
        };
        console.log(params);
        if (values.admin) {
          params.GroupName = 'Admin';
          cognitoIdentitySP.adminAddUserToGroup(params).promise();
        }
        if (values.investor) {
          params.GroupName = 'investor';
          cognitoIdentitySP.adminAddUserToGroup(params).promise();
        }
      }
      setValues(initialValues);
      setShowSpinner(false);
    } catch (err) {
      setShowSpinner(false);
      if (String(err).includes('UsernameExistsException')) {
        alert(`User with email ${values.email} already exists.`);
      }
      console.log(String(err));
    }
  }

  return (
    <Main>
      {showSpinner && <Spinner />}
      <div className='column'>
        <h4>User Details</h4>
        <form className='details'>
          <p>
            <label> First Name: </label>{' '}
            <input
              type='text'
              value={values['custom:firstName']}
              name='custom:firstName'
              onChange={handleChange}
            />
          </p>
          <p>
            <label> Last Name: </label>
            <input
              type='text'
              name='custom:lastName'
              value={values['custom:lastName']}
              onChange={handleChange}
            />
          </p>
          <p>
            <label> Email: </label>
            <input
              type='text'
              name='email'
              size='35'
              value={values.email}
              onChange={handleChange}
            />
          </p>
          <div className='formRow'>
            <div className='inlineGroup'>
              <label> Admin: </label>
              <input
                type='checkbox'
                name='admin'
                checked={values.admin}
                onChange={handleCheckbox}
              />
            </div>
            <div className='inlineGroup'>
              <label> Investor / VIP: </label>
              <input
                type='checkbox'
                name='investor'
                checked={values.investor}
                onChange={handleCheckbox}
              />
            </div>
          </div>
          <button onClick={handleSubmit}>Create User</button>
        </form>
      </div>
    </Main>
  );
};

export default UserAdd;
