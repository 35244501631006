import styled from 'styled-components';

export const Container = styled.div`
  border: ${({ theme }) => theme.borderMuted};
  margin: 0 1.5rem 0 0;
  padding: 0 0 0 0.5rem;
  display: flex;
  align-items: center;

  input {
    margin: 0 0 0 0.5rem;
    border: none;

    &:focus {
      border: none;
    }
  }

  @media screen and (max-width: 390px) {
    padding: 0;
    margin: 0;
    input {
      width: 230px;
    }
  }
`;
