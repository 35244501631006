import React, { useState } from 'react';
import { fetchNFTOrders } from '../../../utils/api';
import useForm from '../../../utils/useForm';
import { fromWei } from '../../../utils/useWeb3';
import { Container } from './NFTOrder.styles';
import validate from './validate';

const NFTOrders = (props) => {
  const limit = 200;
  const [idx, setIdx] = useState(0);
  const [orders, setOrders] = useState([]);
  const { values, handleSubmit, handleChange } = useForm(submit, validate, {
    startDate: '',
    endDate: '',
    chain: 'matic',
  });

  async function submit() {
    const result = await fetchNFTOrders(
      values.chain,
      values.startDate,
      values.endDate,
      idx * limit
    );
    setOrders(result.data);
  }

  return (
    <Container>
      <h3>NFT Resale Orders</h3>
      {values && (
        <form onSubmit={handleSubmit}>
          <input
            type='date'
            name='startDate'
            id='startDate'
            onChange={handleChange}
            value={values.startDate}
          />
          <input
            type='date'
            name='endDate'
            id='endDate'
            onChange={handleChange}
            value={values.endDate}
          />
          <select
            name='chain'
            id='chain'
            onChange={handleChange}
            value={values.chain}
          >
            <option value='matic'>Polygon / Matic</option>
            <option value='ethereum'>Ethereum</option>
            <option value=''>All Chains</option>
          </select>
          <button type='submit'>Search Orders</button>
        </form>
      )}
      <ul>
        {orders.map((order, i) => (
          <li key={order._id}>
            <div className='details'>
              <span className='num'>No: {i + 1}</span>
              <p>Date: {order.created}</p>
              <p>Chain: {order.chain}</p>
              <p>Value: {fromWei(order.amount.value.toString(), 'ether')}</p>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  );
};

export default NFTOrders;
