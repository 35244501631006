import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  margin: 1rem auto;
  padding-top: ${({ theme }) => theme.navHeight};
  width: 100%;
  max-width: 1700px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Main = styled.main`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Section = styled.div`
  width: 97%;
  max-width: 1500px;
  padding: 1rem 2rem;

  section {
    width: 100%;
  }

  h6 {
    span {
      margin-left: 0.7rem;
      font-weight: 400;
      color: ${({ theme }) => theme.fontMuted};
    }
  }

  form {
    margin: 2rem auto;
    max-width: 850px;
  }
  .inputGroup {
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }
  .inlineGroup {
    display: flex;
    align-items: center;
    margin-right: 2rem;

    input[type='checkbox'] {
      margin-left: 1rem;
    }
  }

  .formRow {
    display: flex;
    max-width: 760px;
  }

  .formList {
    min-height: 20px;
    max-width: 445px;
    background: #313131;
    padding: 5px;
    overflow: auto;
    margin-bottom: 1rem;

    li {
      display: flex;
    }
  }
  .formHeader {
    font-weight: 600;
    font-size: 18px;
    margin-right: 1rem;
  }
  .formListItems {
    margin-top: 0.7rem;
    max-width: unset;
    width: 100%;
  }

  .delUser {
    background: red;
    color: white;
  }
  .delBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    margin-left: 10px;
    border-radius: 50%;
    background: red;
    color: white;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    cursor: pointer;
  }

  .labelHeading {
    margin-bottom: 1rem;
  }

  .discounts {
    margin: 1.5rem auto;
    width: 85%;
  }

  input,
  textarea,
  select {
    height: 45px;
    width: unset;
    border: 1px solid #ffffff33;
    background: none;
    color: rgba(255, 255, 255, 0.4);
    padding: 0.5rem 1rem;
    margin: 0.75rem 0;
    font-size: 1rem;
  }

  button {
    margin-left: 1rem;
    padding: 0.25rem 0.5rem;
  }
  textarea {
    width: 700px;
    height: unset;
  }
  select {
    display: inline-block;
    background: none;
    //width: 140px;
    border: ${({ theme }) => theme.borderMuted};
    color: ${({ theme }) => theme.fontColor};
    padding: 8px;
    font: ${({ theme }) => theme.font.small};
  }

  option {
    background: ${({ theme }) => theme.primaryColor};
  }

  input[type='file'] {
    width: 335px;
  }

  /* &:hover {
    border: 1px solid #ffffff;
  }

  &:focus-visible {
    border: 1px solid #ffffff;
    background-color: rgba(255, 255, 255, 0.1);
    outline: none;
    color: #ffffff;
  }

  &.error {
    color: ${({ theme }) => theme.fontColor};
    outline: none;
    border: 1px solid ${({ theme }) => theme.errorColor};
  } */
`;
