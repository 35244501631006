import React from 'react';

const CardEdit = (props) => {
  return (
    <div>
      <h3>Edit Card</h3>
    </div>
  );
};

export default CardEdit;
