import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  form {
    margin-top: 0.75rem;
  }

  label,
  select {
    margin-right: 1rem;
  }
  .contract {
    font-size: 0.8rem;
  }

  .issue-card {
    margin-bottom: 1rem;
  }
`;
