import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem 3rem;

  ul {
    list-style: none;
    margin-top: 1rem;
  }
  li {
    padding: 0.5rem;
  }
`;
