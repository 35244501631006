import React from 'react';
import { Container, Menu } from './AdminMenu.styles';
import { NavLink, useRouteMatch } from 'react-router-dom';

const AdminMenu = ({ menuItems, menuType }) => {
  const { url } = useRouteMatch();
  return (
    <Container>
      <Menu menuType={menuType}>
        {menuItems.map((item, i) => (
          <NavLink
            to={`${url}${item.replace(' ', '-').toLowerCase()}`}
            key={i}
            activeClassName='active'
          >
            <li className='menuItem'>{item}</li>
          </NavLink>
        ))}
      </Menu>
    </Container>
  );
};

export default AdminMenu;
