import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: ${({ theme }) => theme.navHeight};
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
`;
export const Main = styled.main`
  padding: 3rem 1.5rem;
  width: 95%;
  max-width: 800px;
  min-height: 70vh;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.primaryColor};
  display: flex;
  flex-direction: column;
  align-items: center;

  label {
    margin: 0.5rem 1.5rem 0.5rem 0;
  }
  button {
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem;
  }
  /* @media screen and (max-width: 1199px) {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
`;
