/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useRef } from 'react';
import { API } from 'aws-amplify';
import profanityList from './profanity.json';


export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
};

function getRate(from, to) {
  const apiName = 'marketplace';
  const path = '/rate';
  const myInit = {
    queryStringParameters: {
      symbol: from,
      convert: to,
    },
  };

  return API.get(apiName, path, myInit);
}

export const fetchCurrentRate = async (from, to) => {
  try {
    const data = await getRate(from, to);
    let rate = data.data.quote[to].price;
    return parseFloat(rate);
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch exchange rate',
      error: err,
    });
    return 0;
  }
};
export const fetchAllCurrentRates = async (from, to) => {
  try {
    const data = await getRate(from, to);
    let rates = data.data.quote;
    return rates;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch multiple exchange rates',
      error: err,
    });
    return 0;
  }
};

export const HandleOutsideClick = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    // if clicked outside, set to false to trigger toggle action in component
    if (
      ref &&
      ref.current &&
      !ref.current.contains(event.target) &&
      isComponentVisible
    ) {
      setIsComponentVisible(false);
    }
    // reset component to initial value
    setIsComponentVisible(true);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);
  return { ref, isComponentVisible, setIsComponentVisible };
};

export const HandleOutsideDropdown = () => {
  const [isComponentVisible, setIsComponentVisible] = useState(true);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    // if clicked outside, set to false to trigger toggle action in component
    if (
      ref.current.classList.contains('visible') &&
      !event.target.classList.contains('dropdown')
    ) {
      setIsComponentVisible(false);
    }
    setIsComponentVisible(true);
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () =>
      document.removeEventListener('click', handleClickOutside, true);
  }, []);
  return { ref, isComponentVisible, setIsComponentVisible };
};

// parse card id from token
export const parseCardId = (tokenId) => {
  const cardIdArr = [];
  cardIdArr.push(tokenId.slice(1, 4));
  cardIdArr.push(tokenId.slice(4, 7));
  cardIdArr.push(tokenId.slice(7, 10));
  cardIdArr.push(tokenId.slice(10, 13));
  cardIdArr.push(tokenId.slice(13, 16));
  const cardId = cardIdArr.join('-');
  return cardId;
};

// split card Id to separate attributes
export const splitCardId = (cardId) => {
  let set = cardId.slice(0, 3);
  let pack = cardId.slice(4, 7);
  let rarity = cardId.slice(8, 11);
  let vRarity = cardId.slice(12, 15);
  let cardNum = cardId.slice(16);
  return { set, pack, rarity, vRarity, cardNum };
};


// escapeSpecialChars will escape any special characters in a string to prepare for regular expressions
const escapeSpecialChars = (regex) => {
  return regex.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
};

export const censorInput = (input) => {
  let isDirty = false;
  const escapedProfanity = profanityList.map(word => escapeSpecialChars(word));
  const regex = new RegExp(escapedProfanity.join('|'), 'gi');
  input = input.replace(regex, match => {
    isDirty = true;
    match = match.split('');
    return match.reduce(prev => prev + '*', '');
  });
  if (isDirty) return [true, input];
  return [isDirty, input];
};




