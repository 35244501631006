const validateReward = (values) => {
  const errors = {};

  if (!values.user.username) errors.username = 'No user Selected';
  if (values.qty < 1) errors.qty = 'Select a Quantity';
  if (values.product === 'default') errors.product = 'Select a Product';
  if (values.product.soldQty + values.Qty > values.product.initialQty)
    errors.product = 'Insufficient Qty Available';
  if (values.program === 'default') errors.program = 'Select a Program';

  return errors;
};

export default validateReward;
