import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { ModalWrapper, Modal } from './RouterPrompt.styles';

export function RouterPrompt(props) {
  const { when, onOK, onCancel } = props;

  const history = useHistory();

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  useEffect(() => {
    if (when) {
      history.block((prompt) => {
        setCurrentPath(prompt.pathname);
        setShowPrompt(true);
        return false;
      });
    } else {
      history.block(() => {});
    }

    return () => {
      history.block(() => {});
    };
  }, [history, when]);

  const handleOK = useCallback(async () => {
    if (onOK) {
      const canRoute = await Promise.resolve(onOK());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
  }, [currentPath, history, onOK]);

  const handleCancel = useCallback(async () => {
    if (onCancel) {
      const canRoute = await Promise.resolve(onCancel());
      if (canRoute) {
        history.block(() => {});
        history.push(currentPath);
      }
    }
    setShowPrompt(false);
  }, [currentPath, history, onCancel]);

  return showPrompt ? (
    <ModalWrapper>
      <Modal>
        <h4>Leaving Page</h4>
        <p>There are unsaved changes. </p>
        <p>Are you sure want to leave this page ?</p>
        <div className='controls'>
          <button onClick={handleCancel}>Cancel</button>
          <button onClick={handleOK}>OK</button>
        </div>
      </Modal>
    </ModalWrapper>
  ) : null;
}
