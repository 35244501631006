import React, { useState } from 'react';
import { Container } from '../AdminStyles';
import AdminMenu from '../AdminMenu/AdminMenu.component';
import CardAdd from './CardAdd';
import CardEdit from './CardEdit';
import CardDelete from './CardDelete';

const AdminCard = (props) => {
  const menuItems = ['Add', 'Edit', 'Delete'];
  const [selectedTab, setSelectedTab] = useState(1);
  return (
    <Container>
      <AdminMenu
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        menuItems={menuItems}
        menuType='sub'
      />
      {selectedTab === 0 && <CardAdd />}
      {selectedTab === 1 && <CardEdit />}
      {selectedTab === 2 && <CardDelete />}
    </Container>
  );
};

export default AdminCard;
