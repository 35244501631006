/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { Web3 } from 'web3';
import { Web3Validator } from 'web3';
import config from '../config';
import { USER_REJECTED } from '../constants';

const useWeb3 = () => {
  const [web3] = useState(new Web3(config.ethRpc));

  return { web3 };
};

export default useWeb3;

export const fromWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.fromWei(amount, to);
};
export const toWei = (amount, to = 'ether') => {
  const web3 = new Web3();
  return web3.utils.toWei(amount, to);
};

export const toChecksumAddress = (address) => {
  const web3 = new Web3();
  const validator = new Web3Validator();
  if (validator.isAddress(address)) {
    return web3.utils.toChecksumAddress(address);
  }
  return null;
};

export const checkChainId = async (web3, expectedChain, setter) => {
  if (web3) {
    let id = await web3.eth.getChainId();
    if (id === expectedChain || parseInt(id, 16) === expectedChain)
      setter(false);
    else {
      setter(true);
      await switchChain(web3, expectedChain);
      id = await web3.eth.getChainId();
      if (id === expectedChain || parseInt(id, 16) === expectedChain)
        setter(false);
      else setter(true);
    }
  }
};

async function switchChain(web3, chain) {
  if (!window.ethereum) return;
  try {
    await window.ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: web3.utils.toHex(chain) }],
    });
  } catch (err) {
    if (err.code === USER_REJECTED) return;
    console.log(err);
  }
}
