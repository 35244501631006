/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import Button from '../Button/Button.component';
import { Nav, Image, NavMenuDesktop, NavMenuMobile } from './Navbar.styles';
// import Notification from '../Notification/Notification.component';
import UserMenu from '../UserMenu/UserMenu.component';
import { NavLink, useLocation } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import { Icon } from '../Icons/BaseIcon';

const Navbar = (props) => {
  const { loginModal, toggleLoginModal } = props;
  const { user } = useContext(UserContext);
  const [showMobileNav, setShowMobileNav] = useState(false);

  return (
    <Nav>
      <div className='wrapper'>
        <a href='https://locgame.io' alt='Locgame.io Home'>
          <Image
            src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618d462ee88384dcbe751fd9_NAv%20Logo.svg'
            alt='Logo'
          />
        </a>
      </div>

      {!user ? (
        <div className='login-wrapper'>
          <NavLink to='/login'>
            <Button className='loginBtn' width='140' onClick={toggleLoginModal}>
              LOG IN
            </Button>
          </NavLink>
        </div>
      ) : (
        <div className='wrapper'>
          {/* <Notification /> */}
          <UserMenu
            user={user}
            username={`${user.given_name} ${user.family_name}`}
            picture={user.picture}
          />
        </div>
      )}

      <div className='mobile-toggle' onClick={() => setShowMobileNav(true)}>
        <Icon
          className='nav-icon'
          src='https://assets-global.website-files.com/618a32a0f5709fe2f57298b0/618a8721fb3bf939d6f1f750_hamburger.png'
        />
      </div>
    </Nav>
  );
};

export default React.memo(Navbar);
