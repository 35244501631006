import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .list-item {
    display: flex;
    justify-content: flex-start;
    width: 625px;
  }

  .staking-name {
    width: 150px;
  }

  .product-title {
    width: 300px;
    display: flex;
    justify-content: space-between;
  }

  .controls {
    width: 200px;
    margin-left: 40px;
  }
  h6 {
    margin: 0.75rem 0;
  }
`;
