/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Container } from './CardItem.styles';
import useForm from '../../../utils/useForm';
import validateItem from './ValidateItem';
import { v4 as uuidv4 } from 'uuid';
import { API } from 'aws-amplify';

const CardItem = ({ setProductValues, productValues }) => {
  const [cardRarity, setCardRarity] = useState({
    '001': 0,
    '002': 0,
    '003': 0,
    '004': 0,
    '005': 0,
  });
  const [cardVRarity, setCardVRarity] = useState({
    '001': 0,
    '002': 0,
    '003': 0,
    '004': 0,
    '005': 0,
  });
  const [items, setItems] = useState([]);
  const [packs, setPacks] = useState(null);
  const [editions, setEditions] = useState(null);

  const { values, errors, handleSubmit, handleChange, setValues } = useForm(
    addItem,
    validateItem
  );

  useEffect(() => {
    getPacks();
    getEditions();
    setValues({
      set: '001',
      pack: '000',
      rarity: cardRarity,
      vRarity: cardVRarity,
    });
    if (productValues.items) {
      setItems(productValues.items);
    }
  }, []);

  useEffect(() => {
    setValues((values) => {
      return { ...values, rarity: cardRarity, vRarity: cardVRarity };
    });
  }, [cardRarity, cardVRarity]);

  useEffect(() => {
    setProductValues((prev) => {
      return { ...prev, items };
    });
  }, [items]);

  const updateRarity = (e) => {
    const { name, value } = e.target;
    if (isNaN(value))
      setCardRarity((prev) => {
        return { ...prev, [name]: '' };
      });

    setCardRarity((prev) => {
      return { ...prev, [name]: parseInt(value) / 100 };
    });
  };

  const updateVRarity = (e) => {
    const { name, value } = e.target;
    if (isNaN(value))
      setCardVRarity((prev) => {
        return { ...prev, [name]: '' };
      });

    setCardVRarity((prev) => {
      return { ...prev, [name]: parseInt(value) / 100 };
    });
  };

  function resetValues() {
    setValues({
      set: '001',
      pack: '001',
    });
    setCardRarity({ '001': 0, '002': 0, '003': 0, '004': 0, '005': 0 });
    setCardVRarity({ '001': 0, '002': 0, '003': 0, '004': 0, '005': 0 });
  }

  function addItem() {
    const item = values;
    item.id = uuidv4();
    setItems((prev) => {
      return [...prev, item];
    });
    resetValues();
  }
  function deleteItem(e) {
    const filteredItems = items.filter((item) => item.id !== e.target.id);
    setItems(filteredItems);
  }

  const getPacks = async () => {
    const apiName = 'marketplace';
    const path = '/enum/pack';
    const res = await API.get(apiName, path);
    setPacks(res.values);
  };
  const getEditions = async () => {
    const apiName = 'marketplace';
    const path = '/enum/edition';
    const res = await API.get(apiName, path);
    setEditions(res.values);
  };

  const displayPacks = () => {
    if (!packs) return;
    let keys = Object.keys(packs);
    return keys.map((key) => (
      <option key={key} value={key}>
        {packs[key]}
      </option>
    ));
  };

  const displayEditions = () => {
    if (!editions) return;
    let keys = Object.keys(editions);
    return keys.map((key) => (
      <option key={key} value={key}>
        {editions[key]}
      </option>
    ));
  };

  return (
    <Container>
      {values.set && (
        <div className='innerForm'>
          <div className='formRow'>
            <div className='inputGroup'>
              <label htmlFor='set'>Edition Set</label>
              <select
                name='set'
                id='set'
                value={values.set}
                onChange={handleChange}
              >
                {displayEditions()}
              </select>
            </div>
            <div className='inputGroup'>
              <label htmlFor='pack'>Pack</label>
              <select
                name='pack'
                id='pack'
                value={values.pack}
                onChange={handleChange}
              >
                {displayPacks()}
              </select>
            </div>
          </div>
          <h6 className='labelHeading'>
            Game Rarity <span>in % totaling 100%</span>
          </h6>
          <div className='formRow'>
            <div className='inputGroup'>
              <label htmlFor='common'>Common</label>
              <input
                type='text'
                name='001'
                id='common'
                size='5'
                onChange={updateRarity}
                value={
                  isNaN(cardRarity['001'])
                    ? ''
                    : Math.round(cardRarity['001'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='gold1'>Gold 1</label>
              <input
                type='text'
                name='002'
                id='gold1'
                size='5'
                onChange={updateRarity}
                value={
                  isNaN(cardRarity['002'])
                    ? ''
                    : Math.round(cardRarity['002'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='gold2'>Gold 2</label>
              <input
                type='text'
                name='003'
                id='gold2'
                size='5'
                onChange={updateRarity}
                value={
                  isNaN(cardRarity['003'])
                    ? ''
                    : Math.round(cardRarity['003'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='gold3'>Gold 3</label>
              <input
                type='text'
                name='004'
                id='gold3'
                size='5'
                onChange={updateRarity}
                value={
                  isNaN(cardRarity['004'])
                    ? ''
                    : Math.round(cardRarity['004'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='prestige'>Prestige</label>
              <input
                type='text'
                name='005'
                id='prestige'
                size='5'
                onChange={updateRarity}
                value={
                  isNaN(cardRarity['005'])
                    ? ''
                    : Math.round(cardRarity['005'] * 100)
                }
              />
            </div>
          </div>
          <h6 className='labelHeading'>
            Visual Rarity <span>in % totaling 100%</span>
          </h6>
          <div className='formRow'>
            <div className='inputGroup'>
              <label htmlFor='standard'>Standard</label>
              <input
                type='text'
                name='001'
                id='standard'
                size='5'
                onChange={updateVRarity}
                value={
                  isNaN(cardVRarity['001'])
                    ? ''
                    : Math.round(cardVRarity['001'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='elite'>Prestige 1</label>
              <input
                type='text'
                name='002'
                id='prestige1'
                size='5'
                onChange={updateVRarity}
                value={
                  isNaN(cardVRarity['002'])
                    ? ''
                    : Math.round(cardVRarity['002'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='prestige2'>Prestige 2</label>
              <input
                type='text'
                name='003'
                id='prestige2'
                size='5'
                onChange={updateVRarity}
                value={
                  isNaN(cardVRarity['003'])
                    ? ''
                    : Math.round(cardVRarity['003'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='elite1'>Elite 1</label>
              <input
                type='text'
                name='004'
                id='elite1'
                size='5'
                onChange={updateVRarity}
                value={
                  isNaN(cardVRarity['004'])
                    ? ''
                    : Math.round(cardVRarity['004'] * 100)
                }
              />
            </div>
            <div className='inputGroup'>
              <label htmlFor='elite2'>Elite 2</label>
              <input
                type='text'
                name='005'
                id='elite2'
                size='5'
                onChange={updateVRarity}
                value={
                  isNaN(cardVRarity['005'])
                    ? ''
                    : Math.round(cardVRarity['005'] * 100)
                }
              />
            </div>
          </div>
          <button onClick={handleSubmit}>Add Item</button>
          <div className='formRow'>
            {errors?.errors ? (
              <ul className='formList'>
                {errors.errors.map((error, i) => (
                  <li key={i}>{error}</li>
                ))}
              </ul>
            ) : null}
          </div>
          <div className='formRow'>
            <ul className='formList formListItems'>
              {items.map((item) => (
                <li key={item.id}>
                  {`${item.set}, Edition: ${item.pack}, Rarity: C:${item.rarity['001']} G1:${item.rarity['002']} `}
                  <div className='delBtn' onClick={deleteItem} id={item.id}>
                    X
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CardItem;
