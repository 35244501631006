import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import {
  ModalWrapper,
  Modal,
  CloseBtn,
  Header,
  Content,
} from './SelectionModal.styles';
import { HandleOutsideClick } from '../../utils/utils';

const SelectionModal = (props) => {
  const { sendClose, userList, setUser } = props;
  const { ref, isComponentVisible } = HandleOutsideClick();

  useEffect(() => {
    if (!isComponentVisible) {
      sendClose(null);
    }
  }, [isComponentVisible, sendClose]);

  const handleClose = () => {
    sendClose(null);
  };

  const selectUser = (e) => {
    setUser(userList[e.target.id]);
    sendClose(null);
  };

  return ReactDOM.createPortal(
    <ModalWrapper>
      <Modal ref={ref}>
        <CloseBtn onClick={handleClose} />
        <Header>
          <h3>Select a User</h3>
        </Header>
        <Content>
          <ul>
            {userList.map((user, i) => (
              <li key={user.username} id={i} onClick={selectUser}>
                {user.email}
              </li>
            ))}
          </ul>
        </Content>
      </Modal>
    </ModalWrapper>,
    document.getElementById('portal')
  );
};

export default SelectionModal;
