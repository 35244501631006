/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';

const useForm = (submit, validate, initState) => {
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState(initState || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  const handleChange = (e) => {
    if (!isDirty) setIsDirty(true);
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (validate) {
      setErrors(validate(values));
    }
    setIsSubmitting(true);
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submit(values);
      setIsDirty(false);
    }
  }, [errors]);

  return {
    values,
    setValues,
    errors,
    handleSubmit,
    handleChange,
    setErrors,
    isDirty,
    setIsDirty,
  };
};

export default useForm;
