import styled from 'styled-components';

export const Container = styled.div`
  width: 97%;
  max-width: 1500px;
  padding: 1rem 2rem;

  section {
    width: 100%;
  }

  h3 {
    margin-bottom: 2rem;
  }

  button {
    padding: 0.25rem 0.6rem;
    margin: 0.5rem;
  }

  li {
    padding: 0.5rem;
    &.menuItem {
      background: none !important;
    }
  }
  li:nth-child(even) {
    background: rgba(255, 255, 255, 0.1);
  }
  li:nth-child(odd) {
    background: rgba(255, 255, 255, 0.2);
  }

  header {
    display: flex;
    justify-content: space-between;
    max-width: 920px;
    align-items: center;
    margin-bottom: 1rem;
  }

  .group {
    display: flex;
    align-items: center;
    label {
      margin-left: 1rem;
    }
  }
  .heading {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: bold;

    span {
      font-weight: 400;
      font-size: 14px;
    }
  }
`;
