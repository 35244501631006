import AWS from 'aws-sdk';
import config from '../../config';


export async function authenticateAdmin() {
  const jwt = localStorage.getItem('token');
  if (!jwt) return;
  AWS.config.region = config.cognito.aws_cognito_region;
  AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: config.cognito.aws_cognito_identity_pool_id,
    Logins: {
      [`cognito-idp.${config.cognito.aws_cognito_region}.amazonaws.com/${config.cognito.aws_user_pools_id}`]:
        jwt,
    },
  });
  await AWS.config.credentials.refresh();
  return new AWS.CognitoIdentityServiceProvider({
    apiVersion: '2016-04-18',
  });
}


