/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import { Container, Main, Section } from './QuestSearch.styles';
import Spinner from '../../components/Spinner/Spinner';
import { searchAnalytics } from '../../utils/api';
import { v4 as uuid } from 'uuid';

const QuestSearch = (props) => {
  const limit = 15;
  const [showSpinner, showSpinnerSet] = useState(false);
  const [results, resultsSet] = useState([]);
  const [page, pageSet] = useState(1);
  const [count, countSet] = useState(0);
  const [values, valuesSet] = useState({
    gameMode: 'arena_mode',
    user: '',
    startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      .toISOString()
      .split('T')[0],
    endDate: new Date().toISOString().split('T')[0],
  });

  useEffect(() => {
    search();
  }, [page]);

  function handleChange(e) {
    valuesSet((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }
  async function search(e) {
    showSpinnerSet(true);
    if (e?.target?.id === 'btn-search') {
      pageSet(1);
      countSet(0);
    }
    try {
      const res = await searchAnalytics({
        ...values,
        from: (page - 1) * limit,
      });
      resultsSet(res.results);
      countSet(res.count);
    } catch (err) {
      console.log(err?.message || err);
    } finally {
      showSpinnerSet(false);
    }
  }

  const handleClick = (e) => {
    e.stopPropagation();
    const { id } = e.target;
    if (id === 'back') {
      if (page > 1) {
        pageSet((prev) => prev - 1);
      }
    } else if (id === 'next') {
      if (page < Math.ceil(count / limit)) {
        pageSet((prev) => prev + 1);
      }
    } else if (/btn-\d+/.test(id)) {
      pageSet(parseInt(id.split('-')[1]));
    }
  };

  return (
    <Container>
      {showSpinner && <Spinner />}
      <header className='header'>
        <p className='heading'>Quest Data Search</p>
      </header>
      <Main>
        <Section>
          <div className='input-group'>
            <p className='input-title'>Game Mode</p>
            <select
              name='gameMode'
              onChange={handleChange}
              value={values.gameMode || 'arena_mode'}
            >
              <option value='arena_mode'>Arena Mode</option>
              <option value='story_mode'>Story Mode</option>
              <option value='friendly_match'>Friendly Matches</option>
            </select>
          </div>
          <div className='input-group'>
            <p className='input-title'>User Name or Id</p>
            <input
              type='text'
              placeholder='User name or Id'
              name='user'
              onChange={handleChange}
              value={values.user || ''}
            />
          </div>
          <div className='input-group'>
            <p className='input-title'>Start Date</p>
            <input
              type='date'
              name='startDate'
              id='start'
              onChange={handleChange}
              value={values.startDate}
            />
          </div>
          <div className='input-group'>
            <p className='input-title'>End Date</p>
            <input
              type='date'
              name='endDate'
              id='end'
              onChange={handleChange}
              value={values.endDate}
            />
          </div>
          <div className='input-group'>
            <button id='btn-search' onClick={search}>
              Search
            </button>
          </div>
        </Section>
        {results.length > 0 && (
          <Section className='column'>
            <p className='heading'>Results</p>

            <div className='data'>
              <p className='player-name'>{results[0].user}</p>
              <ul>
                {results.map(
                  ({
                    date,
                    result,
                    user,
                    level,
                    progress,
                    wager,
                    duration,
                  }) => (
                    <li key={date}>
                      <div className='data-item'>{`Date: ${
                        date.split('T')[0]
                      }`}</div>
                      {values.gameMode === 'story_mode' && (
                        <div className='data-item'>{`Progress: ${progress}`}</div>
                      )}
                      {values.gameMode !== 'friendly_match' && (
                        <div className='data-item'>{`Level: ${level}`}</div>
                      )}
                      <div className='data-item'>{`Wager: ${Math.abs(
                        wager
                      )}`}</div>
                      <div
                        className={`data-item ${
                          result === 'winner'
                            ? 'win result'
                            : result === 'looser'
                            ? 'lose result'
                            : 'result'
                        }`}
                      >{`Result: ${
                        result.includes('draw') ? 'Draw' : result
                      }`}</div>
                      <div className='data-item'>{`Game Time: ${`${Math.floor(
                        duration / 60
                      )}:${(duration % 60).toString().padEnd(2, 0)}`}`}</div>
                    </li>
                  )
                )}
              </ul>
              {count > 15 && (
                <div className='pagination'>
                  <div className='back' id='back' onClick={handleClick}>
                    <img src='/images/double_chevron_left.svg' alt='Previous' />
                  </div>
                  <div className='page-list'>
                    {Array.from({ length: Math.ceil(count / 15) }, () =>
                      uuid()
                    ).map((x, i) => (
                      <p
                        key={x}
                        className={page - 1 === i ? 'active' : ''}
                        id={`btn-${i + 1}`}
                        onClick={handleClick}
                      >
                        {i + 1}
                      </p>
                    ))}
                  </div>
                  <div className='next' id='next' onClick={handleClick}>
                    <img src='/images/double_chevron_right.svg' alt='Next' />
                  </div>
                </div>
              )}
            </div>
          </Section>
        )}
      </Main>
    </Container>
  );
};

export default QuestSearch;
