import React, { useState, useEffect } from 'react';
import { Container } from './RewardForm.styles';
import useForm from '../../../utils/useForm';
import { splitCardId } from '../../../utils/utils';
import { API, Auth } from 'aws-amplify';
import validateReward from './validateReward';
import config from '../../../config';
import { getPacks, getVisualRarities } from '../../../utils/api';

const RewardForm = (props) => {
  const { user, cardData } = props;
  const { availableSets } = config;
  const [products, setProducts] = useState(null);
  const [programs, setPrograms] = useState(null);
  const [packs, setPacks] = useState(null);
  const [vRarities, setVRarities] = useState(null);
  const [selectedCard, setSelectedCard] = useState('default');

  const { values, handleSubmit, handleChange } = useForm(
    submitReward,
    validateReward,
    {
      user,
      product: 'default',
      program: 'default',
      qty: 0,
    }
  );

  useEffect(() => {
    getProducts();
    getRewardPrograms();
    getPacks().then((data) => setPacks(data.values));
    getVisualRarities().then((data) => setVRarities(data));
  }, []);

  async function getProducts() {
    let skip = 0;
    const limit = 20;
    const apiName = 'marketplace';
    const path = '/product';
    const res = await API.get(apiName, path, {
      queryStringParameters: {
        skip: skip,
        count: true,
      },
    });
    let productList = res.data.filter(
      (product) => product.soldQty < product.initialQty
    );
    if (res.count > 20) {
      for (let i = 1; i < Math.ceil(res.count / limit); i++) {
        skip = limit * i;
        let nextRes = await API.get(apiName, path, {
          queryStringParameters: {
            skip: skip,
            count: true,
          },
        });
        let products = nextRes.data.filter(
          (product) => product.soldQty < product.initialQty
        );
        productList = [...productList, ...products];
      }
    }
    setProducts(productList);
  }
  async function getRewardPrograms() {
    const apiName = 'marketplace';
    const path = '/enum/staking';
    const res = await API.get(apiName, path);
    setPrograms(res.values);
  }

  const getRewardNames = () => {
    let keys = Object.keys(programs);
    return keys.map((key) => (
      <option key={key} value={key}>
        {programs[key].name}
      </option>
    ));
  };

  async function postAdminOrder(orderInfo) {
    const apiName = 'marketplace';
    const path = '/order/admin';
    const myInit = {
      body: orderInfo,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }
  async function postCardIssueOrder(orderInfo) {
    const apiName = 'marketplace';
    const path = '/reward/issue-card';
    const myInit = {
      body: orderInfo,
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    return await API.post(apiName, path, myInit);
  }

  async function submitReward() {
    const product = products.filter((item) => item._id === values.product)[0];
    if (product.soldQty + parseInt(values.qty) > product.initialQty) {
      alert('Insufficient Product Qty Available');
      return;
    }
    const rewardOrder = {
      iduser: user.username,
      product: { _id: values.product },
      staking: {
        name: programs[values.program].name,
        contract: programs[values.program].contract,
      },
      currency: 'LOCG',
      qty: parseInt(values.qty),
      wallet: values.user['custom:wallet'],
    };
    try {
      await postAdminOrder(rewardOrder);
      props.fetchTransactions();
    } catch (err) {
      console.log(err);
    }
  }

  async function issueCard() {
    if (selectedCard === 'default') return;
    const { name, id } = selectedCard;
    const { set, pack, rarity, vRarity, cardNum } = splitCardId(id);
    let confirm = window.confirm(`Issue a ${name} card to this users?`);
    if (!confirm) {
      setSelectedCard('default');
      return;
    }
    const body = {
      iduser: user.username,
      wallet: user['custom:wallet'],
      items: [
        {
          set,
          pack,
          rarity: { '001': 0, '002': 0, '003': 0, '004': 0, '005': 0 },
          vRarity: { '001': 0, '002': 0, '003': 0 },
          cardNum,
        },
      ],
    };
    body.items[0].rarity[rarity] = 1;
    body.items[0].vRarity[vRarity] = 1;
    try {
      await postCardIssueOrder(body);
      setSelectedCard('default');
      props.fetchTransactions();
    } catch (error) {
      console.log('Error Posting Order', error);
    }
  }

  return (
    <Container>
      <h4>Issue Single Card</h4>
      {cardData && (
        <div className='issue-card'>
          <select
            name='card'
            id='card'
            onChange={(e) => {
              let card = cardData.filter(
                (card) => card._id === e.target.value
              )[0];
              setSelectedCard(card);
            }}
            value={selectedCard === 'default' ? 'default' : selectedCard._id}
          >
            <option value='default' disabled>
              Choose a Card
            </option>
            {vRarities &&
              packs &&
              cardData
                .filter((card) => availableSets.includes(card.set))
                .sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                  return 1;
                })
                .map((card) => (
                  <option key={card._id} value={card._id}>
                    {`${card.set} - ${card.name} - ${packs[card.pack]} - ${
                      vRarities[card.vRarity]
                    }`}
                  </option>
                ))}
          </select>
          <button onClick={issueCard}>Issue Card</button>
        </div>
      )}
      <h4>Issue Packs</h4>
      <form>
        <div>
          <label htmlFor='product'>Product</label>
          <select
            name='product'
            id='product'
            value={values.product}
            onChange={handleChange}
          >
            <option value='default' disabled>
              Choose a Product
            </option>
            {products &&
              products.length > 0 &&
              products.map((product) => (
                <option value={product._id} key={product._id}>
                  {product?.items[0]?.set} - {product.title}
                </option>
              ))}
          </select>
          <label htmlFor='qty'>Quantity</label>
          <input
            type='text'
            name='qty'
            id='qty'
            size='5'
            value={values.qty}
            onChange={handleChange}
          />
        </div>
        <div>
          <label htmlFor='program'>Program</label>
          <select
            name='program'
            id='program'
            value={values.program}
            onChange={handleChange}
          >
            <option value='default' disabled>
              Choose a Program
            </option>
            {programs && getRewardNames()}
          </select>
          <label className='contract' htmlFor='program'>
            {values.program && values.program !== 'default'
              ? programs[values.program].contract
              : 'Contract Address Not Available'}
          </label>
        </div>
        <button onClick={handleSubmit}>Submit Reward Order</button>
      </form>
    </Container>
  );
};

export default RewardForm;
