import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 60px 30px;
  background: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.secondaryColor}, ${theme.footerColor} 20%)`};

  @media screen and (max-width: 470px) {
    padding: 40px 10px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  .footer-logos {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 45px;

    img {
      width: 205px;
      padding: 41px;
      object-fit: scale-down;
    }
  }

  .footer-content {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 45px;
  }

  .footer-group {
    display: flex;
    flex-wrap: wrap;
  }

  .footer-row {
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    a,
    p {
      color: rgba(255, 255, 255, 0.5);
      font: ${({ theme }) => theme.font.footer};
      margin-right: 1rem;
    }
  }

  @media screen and (max-width: 1160px) {
    .footer-logos {
      img {
        width: 117px;
        padding: 20px;
      }
    }
    .footer-content {
      justify-content: flex-start;
    }
  }
`;

export const Column = styled.div`
  padding: 30px;
  min-width: 200px;

  .footer-logo {
    width: 112px;
    margin-bottom: 20px;
  }

  .footer-list {
    list-style: none;
  }

  .footer-list-item {
    font: ${({ theme }) => theme.font.footer};
    margin-bottom: 4px;
  }

  .item-wrapper {
    width: 145px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .icon-wrapper {
      width: 45px;
      height: 45px;
      margin-right: 1rem;
      border-radius: 50%;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        filter: invert(100%);
      }
    }

    p {
      font: ${({ theme }) => theme.font.footer};
    }
  }

  .heading {
    font: ${({ theme }) => theme.font.footerHeading};
    margin-bottom: 1.25rem;
  }
`;
