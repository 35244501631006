import React from 'react';
import { Container } from './RewardList.styles';
import { API, Auth } from 'aws-amplify';
import { parseCardId } from '../../../utils/utils';

const RewardList = (props) => {
  const processOrder = async (e) => {
    const route = e.target.id;
    const apiName = 'marketplace';
    const path =
      route === 'reprocess'
        ? `/order/reprocess/${e.target.name}`
        : `/order/admin/process/${e.target.name}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    try {
      await API.get(apiName, path, myInit);
      await props.fetchTransactions();
    } catch (err) {
      console.log(err);
    }
  };

  const deleteOrder = async (e) => {
    const apiName = 'marketplace';
    const path = `/order/${e.target.name}`;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`,
      },
    };

    try {
      await API.del(apiName, path, myInit);
      const updatedTransactions = props.transactions.filter(
        (item) => item._id !== e.target.name
      );
      props.setTransactions(updatedTransactions);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <h6>Reward Packs</h6>
      <ul>
        {props.transactions.map((item) => (
          <li className='list-item' key={item._id}>
            <div className='staking-name'>{item?.staking?.name}</div>
            <div className='product-title'>
              <p>{item.product.title}</p> <p>{item.qty}</p>
            </div>
            <div className='controls'>
              {item.status === 'Complete' ? (
                'Complete'
              ) : item.status === 'Failed' ? (
                'Failed'
              ) : item.status === 'In Progress' ? (
                <>
                  <button name={item._id} onClick={processOrder}>
                    Process
                  </button>
                  <button name={item._id} onClick={deleteOrder}>
                    Delete
                  </button>
                </>
              ) : (
                'Processing'
              )}
            </div>
          </li>
        ))}
      </ul>
      <h6>Issued Cards</h6>
      {props.cardData && (
        <ul>
          {props.issuedCards.map((card) => {
            let cardId = parseCardId(card.tokens[0].token);
            let { name: cardName, vRarity } = props?.cardData?.filter(
              (item) => item.id === cardId
            )[0];
            return (
              <li className='list-item' key={card._id}>{`${cardName} ${
                vRarity === '001'
                  ? 'Standard'
                  : card.vRarity === '002'
                  ? 'Prestige'
                  : 'Elite'
              } - ${card.status}`}</li>
            );
          })}
        </ul>
      )}
    </Container>
  );
};

export default RewardList;
