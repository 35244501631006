import React, { useEffect, useState } from 'react';
import {
  createDiscount,
  deleteDiscount,
  updateDiscount,
} from '../../../utils/api';
import { Icon } from '../../Icons/BaseIcon';
import { Container } from './DiscountItem.styles';

const DiscountItem = ({ discount, updateDiscounts }) => {
  const isNew = discount.isNew ? true : false;
  const [data, setData] = useState(null);
  const [isEditing, setIsEditing] = useState(isNew);

  useEffect(() => {
    formatData(discount);
  }, [discount]);

  const formatData = (data) => {
    const discountData = {
      code: data.code || Math.random().toString(36).substr(2, 10).toUpperCase(),
      type: data.type,
      value:
        data.type === 'percent'
          ? `${data.value * 100}%`
          : `$ ${data.value.toFixed(2)}`,
      active: data.active,
      singleUse: data.isSingleUse || false,
    };
    setData(discountData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreate = async () => {
    console.log(data);
    let { code, type, active, value, singleUse } = data;
    if (!code || !type || !value) return;
    value = value?.replace(/[^0-9.]/g, '');
    if (type === 'percent') value = value / 100;
    if (type === 'dollar') value = parseFloat(value).toFixed(2);
    console.log(code, type, active, value);
    await createDiscount({
      code,
      type,
      active,
      value,
      isPending: false,
      isSingleUse: singleUse,
    });
    setIsEditing(false);
    updateDiscounts();
  };

  const handleSave = async () => {
    let { code, type, active, value, singleUse } = data;
    if (!code || !type || !value) return;
    value = value?.replace(/[^0-9.]/g, '');
    if (type === 'percent') value = value / 100;
    if (type === 'dollar') value = parseFloat(value).toFixed(2);
    await updateDiscount({
      code,
      type,
      active,
      value,
      isPending: false,
      isSingleUse: singleUse,
    });
    setIsEditing(false);
    updateDiscounts();
  };

  const handleDelete = async () => {
    await deleteDiscount(data.code);
    updateDiscounts();
  };
  const handleCancel = async () => {
    if (isNew) {
      updateDiscounts();
      return;
    }
    formatData(discount);
    setIsEditing(false);
  };

  return (
    <Container>
      {data && (
        <>
          {isNew ? (
            <input
              type='text'
              name='code'
              id='code'
              value={data.code}
              onChange={handleChange}
              disabled={!isEditing}
            />
          ) : (
            <p className='code'>{discount.code} </p>
          )}
          <select
            name='type'
            id='type'
            value={data.type}
            onChange={handleChange}
            disabled={!isEditing}
          >
            <option value='percent'>Percentage</option>
            <option value='dollar'>Dollar (USD)</option>
          </select>
          <input
            type='text'
            name='value'
            id='value'
            value={data.value}
            onChange={handleChange}
            disabled={!isEditing}
          />
          <select
            name='singleUse'
            id='singleUse'
            value={data.singleUse}
            onChange={handleChange}
            disabled={!isEditing}
          >
            <option value={true}>Single Use</option>
            <option value={false}>Multi Use</option>
          </select>
          <select
            name='active'
            id='active'
            value={data.active}
            onChange={handleChange}
            disabled={!isEditing}
          >
            <option value={true}>Active</option>
            <option value={false}>Inactive</option>
          </select>
          <div className='controls'>
            {isEditing ? (
              <>
                <Icon
                  className='btn'
                  src='/images/save.svg'
                  width={20}
                  height={20}
                  margin='0 7px'
                  onClick={isNew ? handleCreate : handleSave}
                />
                <Icon
                  className='btn'
                  src='/images/cancel.svg'
                  width={15}
                  height={15}
                  margin='0 10px'
                  onClick={handleCancel}
                />
              </>
            ) : (
              <>
                <Icon
                  className='btn'
                  src='/images/edit.svg'
                  width={15}
                  height={15}
                  margin='0 10px'
                  onClick={() => setIsEditing(true)}
                />
                <Icon
                  className='btn'
                  src='/images/trashcan.svg'
                  width={15}
                  height={15}
                  margin='0 10px'
                  onClick={handleDelete}
                />
              </>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

export default DiscountItem;
