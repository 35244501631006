import { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [user, setUser] = useState(null);
  const [walletAddress, setWalletAddress] = useState(null);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        walletAddress,
        setWalletAddress,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
