import { Auth, API } from 'aws-amplify';

export const searchAnalytics = async (body) => {

  const apiName = 'marketplace';
  const path = '/es';
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
      "Content-Type": "application/json"
    },
    body,
  };

  return await API.post(apiName, path, options);
};

export const fetchNFTOrders = async (chain, startDate, endDate, skip = 0, limit = 200, sort = "created=-1") => {

  const apiName = 'marketplace';
  const path = '/nft-order';
  const options = {
    headers: {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    },
    queryStringParameters: {
      chain,
      startDate,
      endDate,
      skip,
      limit,
      count: true,
      sort

    }
  };

  return await API.get(apiName, path, options);
};


// Product Calls
export const getProducts = async () => {
  let skip = 0;
  let limit = 20;
  let products;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/product';
  const options = {
    queryStringParameters: {
      limit,
      skip,
      count: 'true',
    },
  };

  try {
    let res = await API.get(apiName, path, options);
    products = res.data;
    while (hasMore) {
      skip++;
      const options = {
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      products = [...products, ...res.data];
      if (res.data.length < 1) {
        hasMore = false;
      }
    }
    return products;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Products',
      error: err,
    });
  }

};



// Product Related Calls

export const getProductTypes = async () => {
  const apiName = 'marketplace';
  const path = '/enum/productType';
  const res = await API.get(apiName, path);
  return res.values;
};
export const getProductEditions = async () => {
  const apiName = 'marketplace';
  const path = '/enum/edition';
  const res = await API.get(apiName, path);
  return res.values;
};

export const getPacks = async () => {
  const apiName = 'marketplace';
  const path = '/enum/pack';
  return await API.get(apiName, path);
};

export const getVisualRarities = async () => {
  const apiName = 'marketplace';
  const path = '/enum/vRarity';
  try {
    let res = await API.get(apiName, path);
    return res.values;
  } catch (err) {
    console.log(err);
  }
};

export const getBanishedIds = async () => {
  const apiName = 'marketplace';
  const path = '/enum/banishedIds';
  const result = await API.get(apiName, path);
  return result.values;
};

// Discount Calls

export const getDiscount = async (code) => {
  const apiName = 'marketplace';
  const path = `/discount/${code}`;
  return await API.get(apiName, path);
};
export const getAllDiscounts = async () => {
  let discounts = [];
  let skip = 0;
  const limit = 20;
  const apiName = 'marketplace';
  const path = `/discount/`;
  const options = {
    queryStringParameters: {
      skip,
      limit
    }
  };
  try {
    let res = await API.get(apiName, path, options);
    discounts = [...res.data];
    while (res.data.length > 0) {
      options.queryStringParameters.skip = res.skip + limit;
      res = await API.get(apiName, path, options);
      discounts = [...discounts, ...res.data];
    }
    return Promise.resolve(discounts);
  } catch (err) {
    console.log(err);
  }

  return await API.get(apiName, path);
};

export const createDiscount = async (data) => {
  const apiName = 'marketplace';
  const path = `/discount`;
  const options = {
    body: data
  };
  return await API.post(apiName, path, options);
};

export const updateDiscount = async (data) => {
  const apiName = 'marketplace';
  const path = `/discount/${data.code}`;
  const options = {
    body: data
  };
  return await API.put(apiName, path, options);
};

export const deleteDiscount = async (code) => {
  const apiName = 'marketplace';
  const path = `/discount/${code}`;
  return await API.del(apiName, path);
};


// Card Calls

export async function getCards() {
  let skip = 0;
  let limit = 200;
  let cards;
  let hasMore = true;
  const apiName = 'marketplace';
  const path = '/card';
  const options = {
    response: true,
    queryStringParameters: { limit, count: true, skip: limit * skip },
  };

  try {
    let res = await API.get(apiName, path, options);
    cards = res.data.data;
    while (hasMore) {
      skip++;
      const options = {
        response: true,
        queryStringParameters: { limit, count: true, skip: limit * skip },
      };
      res = await API.get(apiName, path, options);
      cards = [...cards, ...res.data.data];
      if (res.data.data.length < 1) {
        hasMore = false;
      }
    }
    return cards;
  } catch (err) {
    console.log({
      level: 'Error',
      message: 'Unable to fetch Cards',
      error: err,
    });
  }
}

