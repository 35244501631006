import styled from 'styled-components';

export const Container = styled.li`
  width: 900px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  .title {
    width: 250px;
  }
  .type {
    width: 55px;
  }
  .qty {
    width: 85px;
  }
  .description {
    width: 325px;
  }
  .btn {
    display: flex;
    align-items: center;
    width: 75px;
    margin-left: 10px;
    padding: 0.75rem 0.5rem;
  }
`;
